.card-grid-products {
	display: grid;
	row-gap: 1.25rem;
	column-gap: 1.25rem;
	grid-template-columns: 1fr 1fr;
}

@media (--mq-from-small) {
	.card-grid-products {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media (--mq-from-medium) {
	.card-grid-products {
		row-gap: 1.5rem;
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (--mq-from-wide) {
	.card-grid-products {
		row-gap: 2rem;
		column-gap: 2rem;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media (--mq-from-large) {
	.card-grid-products {
		row-gap: 2.5rem;
		column-gap: 3.75rem;
	}
}
