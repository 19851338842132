:root {
	--color-black: #000000;
	--color-grey-light: #c2c2c2;
	--color-grey: #959595;
	--color-red: #ff8888;
	--color-white: #ffffff;
	--color-gold: #e0c69d;
	/*  */
	--color-background-collection-label: #c7b8b8;
	/*  */
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 700;
	/*  */
	--font-sans: "Montserrat", "Helvetica Neue", sans-serif;
	/*  */
	--border-radius-xsmall: 5px;
	--border-radius-small: 6px;
	--border-radius-medium: 10px;
	--border-radius-large: 20px;
	--border-radius-xlarge: 40px;
	/*  */
	--site-header-height-small: 50px;
	--site-header-height-medium: 70px;
	--site-header-height-large: 100px;
	/*  */
	--z-index-popup: 103;
	--z-index-popup-background: 102;
	--z-index-site-header: 101;
	--z-index-mobile-navigation: 100;
	--z-index-collection: 99;
	--z-index-collection-filter-dropdown: 99;
}
