.site-footer {
	padding-top: 1.25rem;
	padding-bottom: 2rem;
}

@media (--mq-from-medium) {
	.site-footer {
		padding-top: 2rem;
	}
}

@media (--mq-from-large) {
	.site-footer {
		padding-top: 4rem;
		padding-bottom: 2.5rem;
	}
}

.site-footer__wrapper {
	box-sizing: border-box;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	width: 100%;
}

@media (--mq-from-large) {
	.site-footer__wrapper {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 82.5%;
		max-width: 1580px;
	}
}

.site-footer__title {
	display: block;
	text-align: center;
}

.site-footer__menus,
.site-footer__menus__column {
	display: flex;
	flex-wrap: wrap;
}

.site-footer__menus {
	margin-top: 2rem;
}

@media (--mq-from-medium) {
	.site-footer__menus {
		margin-top: 3.125rem;
	}
}

@media (--mq-from-large) {
	.site-footer__menus {
		margin-top: 5rem;
	}
}

.site-footer__menus:only-child {
	margin-top: 0;
}

@media (--mq-up-to-medium) {
	.site-footer__menus__column {
		flex-direction: column;
	}
}

@media (--mq-up-to-medium) {
	.site-footer__menus__column:not(:only-child) {
		flex-basis: calc((100% - 1.25rem) / 2);
	}
}

@media (--mq-up-to-medium) {
	.site-footer__menus__column + .site-footer__menus__column {
		margin-left: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.site-footer__menus__column,
	.site-footer__menus__list {
		flex-basis: calc((100% - 1.25rem) / 2);
	}
}

@media (--mq-from-large) {
	.site-footer__menus__column,
	.site-footer__menus__list {
		flex-basis: calc((100% - 4.375rem) / 2);
	}
}

@media (--mq-from-medium) {
	.site-footer__menus__column + .site-footer__menus__column,
	.site-footer__menus__list + .site-footer__menus__list {
		margin-left: 1.25rem;
	}
}

@media (--mq-from-large) {
	.site-footer__menus__column + .site-footer__menus__column,
	.site-footer__menus__list + .site-footer__menus__list {
		margin-left: 4.375rem;
	}
}

@media (--mq-up-to-medium) {
	.site-footer__menus__column:not(:only-child) .site-footer__menus__list:last-child:not(:only-child) {
		border-top: 1px solid rgb(0 0 0 / 0.3);
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}
}
