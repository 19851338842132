.primary-navigation {
	color: currentColor;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

@media (--mq-from-medium) {
	.primary-navigation {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
}

@media (--mq-up-to-medium) {
	.primary-navigation__item {
		margin-bottom: 0.6875rem;
		text-align: center;
		width: 100%;
	}
}

@media (--mq-from-medium) {
	.primary-navigation__item {
		margin-right: 0.9375rem;
		margin-left: 0.9375rem;
	}
}

@media (--mq-from-large) {
	.primary-navigation__item {
		margin-right: 1.375rem;
		margin-left: 1.375rem;
	}
}

@media (--mq-from-medium) {
	.primary-navigation__item:first-child,
	.primary-navigation__item:only-child {
		margin-left: 0;
	}
}

@media (--mq-up-to-medium) {
	.primary-navigation__item:last-child,
	.primary-navigation__item:only-child {
		margin-bottom: 0;
	}
}

@media (--mq-from-medium) {
	.primary-navigation__item:last-child,
	.primary-navigation__item:only-child {
		margin-right: 0;
	}
}

.primary-navigation__link {
	color: currentColor;
	font-size: 1.375rem;
	line-height: 1.3636;
	text-decoration: none;
}

@media (--mq-from-medium) {
	.primary-navigation__link {
		font-size: 0.9375rem;
		font-weight: var(--font-weight-medium);
		line-height: 1.2667;
		transition: color 96ms linear;
	}
}

@media (--mq-from-large) {
	.primary-navigation__link {
		font-size: 1rem;
		line-height: 1.1875;
	}
}

@media (--mq-up-to-medium) {
	.primary-navigation__item.current-menu-item .primary-navigation__link {
		font-weight: var(--font-weight-medium);
		text-decoration: underline;
	}
}

.primary-navigation__link:focus {
	outline: 0;
}
