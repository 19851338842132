/*
 * 🚨 HEADS UP!
 *
 * Sections have a lot of variation throughout the design, a of them are
 * module specific. Check the designs closely and check to see if the content
 * you're styling should use it's own specific spacing.
 *
 * This class should only be used for non-specific content that
 * needs a bottom- and top-spacer to keep the layout flowing.
 *
 */

.section {
	margin-top: 1.875rem;
	margin-bottom: 1.875rem;
	position: relative;
}

@media (--mq-from-small) {
	.section {
		margin-top: 2.25rem;
		margin-bottom: 2.25rem;
	}
}

@media (--mq-from-medium) {
	.section {
		margin-top: 2.6875rem;
		margin-bottom: 2.6875rem;
	}
}

@media (--mq-from-wide) {
	.section {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media (--mq-from-large) {
	.section {
		margin-top: 5.375rem;
		margin-bottom: 5.375rem;
	}
}

.section.section--medium {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	.section.section--medium {
		margin-top: 3.25rem;
		margin-bottom: 3.25rem;
	}
}

@media (--mq-from-large) {
	.section.section--medium {
		margin-top: 4.375rem;
		margin-bottom: 4.375rem;
	}
}

.section.section--small {
	margin-top: 0.9375rem;
	margin-bottom: 0.9375rem;
}

@media (--mq-from-medium) {
	.section.section--small {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-large) {
	.section.section--small {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

.section.section--video-embed-player:first-child {
	margin-top: 0;
}
