.banner {
	color: var(--color-black);
	padding: 1.875rem 1.25rem;
	position: relative;
}

@media (--mq-from-small) {
	.banner {
		padding: 2.25rem 25%;
	}
}

@media (--mq-from-medium) {
	.banner {
		padding-right: 27.5%;
		padding-left: 27.5%;
	}
}

@media (--mq-from-wide) {
	.banner {
		padding: 6.625rem 33.3333%;
	}
}

.banner__content {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	width: 100%;
	max-width: 655px;
}

@media (--mq-from-wide) {
	.banner__content > p {
		font-size: 1.25rem;
		line-height: 1.8;
	}
}
