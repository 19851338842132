.card-grid {
	overflow: hidden;
	width: 100%;
}

.card-grid__wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-right: -0.625rem;
	margin-left: -0.625rem;
	overflow: hidden;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% + 1.25rem);
}

@media (--mq-from-wide) {
	.card-grid__wrapper {
		margin-right: -1rem;
		margin-left: -1rem;
		width: calc(100% + 2rem);
	}
}

@media (--mq-from-large) {
	.card-grid__wrapper {
		margin-right: -1.75rem;
		margin-left: -1.75rem;
		width: calc(100% + 3.75rem);
	}
}

.card-grid__item {
	flex-basis: calc(50% - 1.25rem);
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 0.625rem;
	margin-bottom: 0.75rem;
	margin-left: 0.625rem;
}

@media (--mq-from-small) {
	.card-grid__item {
		flex-basis: calc(25% - 1.25rem);
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.card-grid__item {
		margin-bottom: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.card-grid__item {
		flex-basis: calc(20% - 2rem);
		margin-right: 1rem;
		margin-bottom: 2rem;
		margin-left: 1rem;
	}
}

@media (--mq-from-large) {
	.card-grid__item {
		flex-basis: calc(20% - 3.75rem);
		margin-right: 1.875rem;
		margin-bottom: 2.5rem;
		margin-left: 1.875rem;
	}
}
