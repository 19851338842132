.news-grid {
	display: grid;
	grid-template-rows: repeat(8, 1fr);
	grid-template-columns: repeat(2, 1fr);
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

@media (--mq-from-small) {
	.news-grid {
		grid-template-rows: repeat(4, 1fr);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* Use auto rows if available. */
@supports (grid-auto-rows: auto) and (grid-row: auto) {
	.news-grid {
		grid-auto-rows: auto;
		grid-template-rows: none;
	}
}

/* Use column gap if available. */
@supports (grid-column-gap: 1.25rem) {
	.news-grid {
		grid-column-gap: 1.25rem;
	}

	@media (--mq-from-small) {
		.news-grid {
			grid-column-gap: 4rem;
		}
	}

	@media (--mq-from-wide) {
		.news-grid {
			grid-column-gap: 6.25rem;
		}
	}
}

/* Use row gap if available. */
@supports (grid-row-gap: 1.25rem) {
	.news-grid {
		grid-row-gap: 1.25rem;
	}

	@media (--mq-from-small) {
		.news-grid {
			grid-row-gap: 1.875rem;
		}
	}

	@media (--mq-from-wide) {
		.news-grid {
			grid-row-gap: 2.5rem;
		}
	}
}

/* #region Item. */
.news-grid__item {
	padding: 0.25rem; /* Keep grid readable if grid-column/row-gap isn't supported. */
	text-align: center;
}

/* Add explicit placement for browser not supporting auto placement. */
.news-grid__item:nth-child(1n + 1) {
	grid-row: 1;
}

.news-grid__item:nth-child(1n + 3) {
	grid-row: 2;
}

.news-grid__item:nth-child(1n + 5) {
	grid-row: 3;
}

.news-grid__item:nth-child(1n + 7) {
	grid-row: 4;
}

.news-grid__item:nth-child(1n + 9) {
	grid-row: 5;
}

.news-grid__item:nth-child(1n + 11) {
	grid-row: 6;
}

.news-grid__item:nth-child(1n + 13) {
	grid-row: 7;
}

.news-grid__item:nth-child(1n + 15) {
	grid-row: 8;
}

.news-grid__item:nth-child(2n + 1) {
	grid-column: 1;
}

.news-grid__item:nth-child(2n + 2) {
	grid-column: 2;
}

@media (--mq-from-small) {
	.news-grid__item {
		padding: 2rem;
	}

	.news-grid__item:nth-child(1n + 1) {
		grid-row: 1;
	}

	.news-grid__item:nth-child(1n + 5) {
		grid-row: 2;
	}

	.news-grid__item:nth-child(1n + 9) {
		grid-row: 3;
	}

	.news-grid__item:nth-child(1n + 13) {
		grid-row: 4;
	}

	.news-grid__item:nth-child(4n + 1) {
		grid-column: 1;
	}

	.news-grid__item:nth-child(4n + 2) {
		grid-column: 2;
	}

	.news-grid__item:nth-child(4n + 3) {
		grid-column: 3;
	}

	.news-grid__item:nth-child(4n + 4) {
		grid-column: 4;
	}
}

@supports (grid-auto-rows: auto) and (grid-row: auto) {
	.news-grid__item:nth-child(n) {
		grid-row: auto;
		grid-column: auto;
	}
}

@supports (grid-column-gap: 1.25rem) and (grid-row-gap: 1.25rem) {
	.news-grid__item {
		padding: 0;
	}
}
/* #endregion */

/* #region Thumbnail. */
/* Using an extra wrapper for the zoom effect on hover. Copied from card.pcss */
.news-grid__link__thumbnail-wrapper {
	margin-bottom: 0.875rem;
	overflow: hidden;
	padding-bottom: calc(100% / 225 * 277);
	position: relative;
	width: 100%;
}

@media (--mq-from-small) {
	.news-grid__link__thumbnail-wrapper {
		margin-bottom: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.news-grid__link__thumbnail-wrapper {
		margin-bottom: 2.5rem;
	}
}

/* Thumbnail. */
.news-grid__link__thumbnail {
	backface-visibility: hidden;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0) scale(1, 1);
	transform-origin: center center;
	transition: transform 320ms ease-out;
	width: 100%;
	height: auto;
}

.news-grid__link__thumbnail-wrapper:focus .news-grid__link__thumbnail,
.news-grid__link__thumbnail-wrapper:hover .news-grid__link__thumbnail {
	transform: translate3d(-50%, -50%, 0) scale(1.1, 1.1);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}
/* #endregion */

/* #region Link. */
.news-grid__link {
	color: currentColor;
	text-decoration: none;
}
/* #endregion */

/* #region Title. */
.news-grid__link__title {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.8571;
	margin-top: 0;
	margin-bottom: 0;
}

@media (--mq-from-wide) {
	.news-grid__link__title {
		font-size: 1rem;
		line-height: 1.875;
	}
}
/* #endregion */
