.wrapper {
	box-sizing: border-box;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	position: relative;
	width: 100%;
}

@media (--mq-from-large) {
	.wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

@media (--mq-from-small) {
	.wrapper.wrapper--xsmall {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 75%;
		max-width: 600px;
	}
}

@media (--mq-from-wide) {
	.wrapper.wrapper--xsmall {
		width: 41.5%;
		max-width: 800px;
	}
}

@media (--mq-from-small) {
	.wrapper.wrapper--small {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--small {
		padding-right: 6.25rem;
		padding-left: 6.25rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--small {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 60%;
		max-width: 1170px;
	}
}

@media (--mq-from-small) {
	.wrapper.wrapper--medium {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--medium {
		padding-right: 6.25rem;
		padding-left: 6.25rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--medium {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 82.5%;
		max-width: 1580px;
	}
}

@media (--mq-from-small) {
	.wrapper.wrapper--inline-media,
	.wrapper.wrapper--location,
	.wrapper.wrapper--news {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--inline-media,
	.wrapper.wrapper--location,
	.wrapper.wrapper--news {
		padding-right: 6.25rem;
		padding-left: 6.25rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--inline-media,
	.wrapper.wrapper--location,
	.wrapper.wrapper--news {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 62.5%;
		max-width: 1200px;
	}
}

@media (--mq-up-to-medium) {
	.wrapper.wrapper--video-embed-player {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--video-embed-player {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--video-embed-player {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 82.5%;
		max-width: 1580px;
	}
}
