* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	line-height: 1.8571;
}

@media (--mq-from-wide) {
	body {
		font-size: 1rem;
		line-height: 1.875;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
.type-a {
	font-size: 1.625rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.3077;
}

@media (--mq-from-medium) {
	h1,
	.type-a {
		font-size: 1.875rem;
		line-height: 1.3333;
	}
}

@media (--mq-from-wide) {
	h1,
	.type-a {
		font-size: 2.25rem;
		line-height: 1.2222;
	}
}

h2,
.type-b {
	font-size: 1.25rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.5;
}

@media (--mq-from-medium) {
	h2,
	.type-b {
		font-size: 1.375rem;
		line-height: 1.3636;
	}
}

@media (--mq-from-wide) {
	h2,
	.type-b {
		font-size: 1.875rem;
		line-height: 1.3333;
	}
}

h3,
.type-c {
	font-size: 1.125rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.5;
}

@media (--mq-from-medium) {
	h3,
	.type-c {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

@media (--mq-from-wide) {
	h3,
	.type-c {
		font-size: 1.5rem;
	}
}

h4,
h5,
.type-d,
.type-e {
	font-size: 1rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.625;
	text-transform: uppercase;
}

h5,
.type-e {
	opacity: 0.3;
}

h6,
.type-f {
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.8571;
	text-transform: uppercase;
}

.type-g {
	font-size: 0.75rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.8571;
}

@media (--mq-from-medium) {
	.type-g {
		font-size: 0.875rem;
		line-height: 1.5;
	}
}

@media (--mq-from-wide) {
	.type-g {
		font-size: 1rem;
		line-height: 1.875;
	}
}

.type-h {
	font-size: 0.625rem;
	font-weight: var(--font-weight-medium);
	line-height: 1;
	text-transform: uppercase;
}

@media (--mq-from-medium) {
	.type-h {
		font-size: 0.6875rem;
	}
}

@media (--mq-from-wide) {
	.type-h {
		font-size: 0.75rem;
	}
}

p {
	margin-top: 0;
	margin-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	p {
		margin-bottom: 1.5rem;
	}
}

p:only-child,
p:last-child {
	margin-bottom: 0;
}

a:not([class]),
p a {
	color: currentColor;
	font-weight: var(--font-weight-medium);
	transition: opacity 96ms linear;
}

a:focus:not([class]),
p a:focus {
	outline: 0;
}

a:focus:not([class]),
p a:focus,
a:hover:not([class]),
p a:hover {
	opacity: 0.3;
}

i,
em {
	font-style: italic;
	font-weight: var(--font-weight-medium);
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

blockquote {
	margin: 1.25rem auto;
	opacity: 0.4;
	max-width: 412px;
}

@media (--mq-from-medium) {
	blockquote {
		margin-top: 1.625rem;
		margin-bottom: 1.5rem;
	}
}

blockquote > p:first-child {
	font-size: 1.125rem;
	font-style: italic;
	font-weight: var(--font-weight-medium);
	line-height: 1.625rem;
	margin-bottom: 0.625rem;
}

@media (--mq-from-wide) {
	blockquote > p:first-child {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}

.u-centered-text {
	text-align: center;
}
