.mailchimp-subscribe-form {
}

.mailchimp-subscribe-form__fields {
	width: 100%;
}

/* Hide form if success response is shown */

#mce-success-response:not([style="display: none;"]) ~ .mailchimp-subscribe-form__fields {
	display: none;
}

.mailchimp-subscribe-form__error {
	color: var(--color-red);
	margin-bottom: 1.25rem;
	width: 100%;
}

@media (--mq-from-medium) {
	.mailchimp-subscribe-form__error {
		box-sizing: border-box;
		margin-bottom: 1.875rem;
		padding-left: 33.3333%;
	}
}

.mailchimp-subscribe-form__error:last-child {
	margin-bottom: 0;
}
