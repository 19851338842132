.rounded-media {
	border-radius: var(--border-radius-large);
	overflow: hidden;
	position: relative;
}

.rounded-media.rounded-media--has-caption::before {
	background: linear-gradient(to top, rgb(149 149 149 / 0.5) 0%, rgb(149 149 149 / 0) 25%);
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.video-embed-player__poster .rounded-media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.rounded-media__asset {
	display: block;
	width: 100%;
}

.video-embed-player__poster .rounded-media__asset {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.video-embed-player__poster .rounded-media__asset {
		object-fit: cover;
		top: 0;
		transform: none;
		height: 100%;
	}
}

.rounded-media__caption {
	color: var(--color-white);
	line-height: 1;
	position: absolute;
	bottom: var(--border-radius-large);
	left: var(--border-radius-large);
	z-index: 3;
}
