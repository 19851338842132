.form-confirmation {
	box-sizing: border-box;
	display: flex;
	padding: 1.25rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	max-width: 600px;
	height: 400px;
}

@media (--mq-from-medium) {
	.form-confirmation {
		padding: 2.5rem;
	}
}

.form-confirmation p {
	display: block;
	max-width: 320px;
}

@media (--mq-from-wide) {
	.form-confirmation p {
		max-width: 400px;
	}
}
