.form {
	width: 100%;
	max-width: 600px;
}

.form__title {
	display: none;
}

.form__row {
	display: flex;
	margin-bottom: 1.25rem;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
}

@media (--mq-from-medium) {
	.form__row {
		margin-bottom: 1.875rem;
	}
}

.form__row:last-child {
	margin-bottom: 0;
}

.form__row__label-container {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
}

.form__label {
	display: inline-block;
	transform: translateY(-4px);
}

@media (--mq-from-wide) {
	.form__label {
		font-size: 1.125rem;
	}
}

.form__row__field-container {
	flex-basis: 62%;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (min-width: 375px) {
	.form__row__field-container {
		flex-basis: 60%;
	}
}

@media (--mq-from-medium) {
	.form__row__field-container {
		flex-basis: 66.6666%;
	}
}

.form__row__notice-container,
.form__row__submit-container {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (--mq-from-medium) {
	.form__row__notice-container,
	.form__row__submit-container {
		flex-basis: 66.6666%;
	}
}

.form__field,
.form__select,
.form__submit,
.form__textarea {
	background: none;
	border-radius: var(--border-radius-small);
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	padding: 0 0.5rem;
	width: 100%;
}

@media (min-width: 375px) {
	.form__field,
	.form__select,
	.form__submit,
	.form__textarea {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

@media (--mq-from-medium) {
	.form__field,
	.form__select,
	.form__submit,
	.form__textarea {
		border-radius: var(--border-radius-xsmall);
	}
}

.form__field:focus,
.form__select:focus,
.form__submit:focus,
.form__textarea:focus {
	outline: 0;
}

.form__field,
.form__select,
.form__textarea {
	border: 1px solid var(--color-grey-light);
}

.form__field:focus,
.form__select:focus,
.form__textarea:focus {
	border-color: var(--color-black);
}

.form__field.has-errors,
.form__field.mce_inline_error,
.form__select.has-errors,
.form__select.mce_inline_error,
.form__textarea.has-errors,
.form__textarea.mce_inline_error {
	border-color: var(--color-red) !important;
}

.form__field,
.form__select,
.form__submit {
	line-height: 40px;
	height: 40px;
}

@media (--mq-from-medium) {
	.form__field,
	.form__select,
	.form__submit {
		line-height: 47px;
		height: 47px;
	}
}

.form__select {
	appearance: none;
	background-image: url("../images/arrow-down.png");
	background-position: calc(100% - 1rem) 50%;
	background-repeat: no-repeat;
	background-size: 10px 10px;

	/* Leave enough room for the select icon on the right */
	padding-right: 2rem;
}

.form__textarea {
	font-size: 0.875rem;
	line-height: 1.8571;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	height: 120px;
}

@media (--mq-from-medium) {
	.form__textarea {
		height: 141px;
	}
}

@media (--mq-from-wide) {
	.form__textarea {
		font-size: 1rem;
		line-height: 1.875;
	}
}

.form__submit {
	border-radius: var(--border-radius-xlarge);
}

.form__checkbox,
.form__radio {
	position: relative;
}

.form__checkbox:not(:last-child):not(:only-child),
.form__radio:not(:last-child):not(:only-child) {
	margin-bottom: 0.625rem;
}

.form__checkbox:focus,
.form__radio:focus {
	outline: 0;
}

.form__checkbox__input,
.form__radio__input {
	appearance: none;
	border: none;
	box-shadow: none;
	opacity: 0;
	outline: 0;
	pointer-events: none;
	position: absolute;
	top: 0.5625rem;
	left: 0.5625rem;
	width: 0.0625rem;
	height: 0.0625rem;
	z-index: 0;
}

.form__checkbox__label,
.form__radio__label {
	display: inline-block;
	line-height: 1.25rem;
	padding-left: 2rem;
	position: relative;
	transition: color 96ms linear;
	z-index: 1;
}

.form__checkbox__label::after,
.form__radio__label::after {
	background-color: var(--color-black);
	box-sizing: border-box;
	content: "";
	opacity: 0;
	pointer-events: none;
	position: absolute;
	z-index: 3;
}

.form__checkbox__label::before,
.form__radio__label::before {
	background-color: var(--color-white);
	border: 1px solid var(--color-grey);
	box-sizing: border-box;
	content: "";
	position: absolute;
	top: 0.1875rem;
	left: 0;
	width: 0.9375rem;
	height: 0.9375rem;
	z-index: 2;
}

@media (--mq-from-wide) {
	.form__checkbox__label::before,
	.form__radio__label::before {
		top: 0;
		width: 1.25rem;
		height: 1.25rem;
	}
}

.filter .form__checkbox__label::before,
.filter .form__radio__label::before {
	background-color: transparent;
	border: 1px solid var(--color-black);
}

.form__checkbox__label:focus,
.form__radio__label:focus {
	outline: 0;
}

.form__checkbox__label:hover,
.form__radio__label:hover {
	cursor: pointer;
}

.has-errors .form__checkbox__label,
.has-errors .form__radio__label {
	color: var(--color-red);
}

.has-errors .form__checkbox__label::before,
.has-errors .form__radio__label::before {
	border-color: var(--color-red);
}

.form__checkbox__label::after {
	border-radius: 100%;
	top: 0.3125rem;
	left: 0.125rem;
	width: 0.6875rem;
	height: 0.6875rem;
}

@media (--mq-from-wide) {
	.form__checkbox__label::after {
		top: 0.1875rem;
		left: 0.1875rem;
		width: 0.875rem;
		height: 0.875rem;
	}
}

.form__checkbox__input:checked + .form__checkbox__label::after {
	opacity: 1;
}

.form__radio__label::after,
.form__radio__label::before {
	border-radius: 100%;
}

.form__radio__label::after {
	border-radius: 100%;
	top: 0.375rem;
	left: 0.1875rem;
	width: 0.5625rem;
	height: 0.5625rem;
}

@media (--mq-from-wide) {
	.form__radio__label::after {
		top: 0.25rem;
		left: 0.25rem;
		width: 0.75rem;
		height: 0.75rem;
	}
}

.form__radio__input:checked + .form__radio__label::after {
	opacity: 1;
}

.form__help-text {
	display: inline-block;
	font-size: 0.8125rem;
	line-height: 1.2308;
	margin-top: 0.5625rem;
	opacity: 0.45;
}

.form__error-notice {
	color: var(--color-red);
}
