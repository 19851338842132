.showcase-rows {
	overflow: hidden;
	position: relative;
	width: 100%;
}

.showcase-rows__item {
	margin-bottom: 2rem;
}

@media (--mq-from-small) {
	.showcase-rows__item {
		display: flex;
		margin-right: -0.5rem;
		margin-bottom: 4.625rem;
		margin-left: -0.5rem;
		justify-content: center;
		align-items: center;
		width: calc(100% + 1rem);
	}
}

@media (--mq-from-wide) {
	.showcase-rows__item {
		margin-bottom: 4.375rem;
	}
}

.showcase-rows__item:first-child {
	margin-top: 2rem;
}

@media (--mq-from-small) {
	.showcase-rows__item:first-child {
		margin-top: 4.625rem;
	}
}

@media (--mq-from-wide) {
	.showcase-rows__item:first-child {
		margin-top: 4.375rem;
	}
}

@media (--mq-from-small) {
	.showcase-rows__item__content-wrapper,
	.showcase-rows__item__media-wrapper {
		flex-basis: calc(50% - 1rem);
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}

.showcase-rows__item__content-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

@media (--mq-up-to-small) {
	.showcase-rows__item__content-wrapper:not(:only-child) {
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-small) {
	.showcase-rows__item__content {
		margin-right: 5%;
		margin-left: 5%;
	}
}

@media (--mq-from-medium) {
	.showcase-rows__item__content {
		margin-right: 10%;
		margin-left: 10%;
		max-width: 383px;
	}
}

@media (--mq-from-wide) {
	.showcase-rows__item__content {
		margin-right: 15%;
		margin-left: 15%;
	}
}

.showcase-rows__item__media-wrapper {
	overflow: hidden;
}

@media (--mq-from-small) {
	.showcase-rows__item:nth-child(odd) .showcase-rows__item__media-wrapper {
		order: -1;
	}
}

.showcase-rows__item__media,
.showcase-rows__item__media-link {
	display: block;
	width: 100%;
}

.showcase-rows__item__media-link {
	border-radius: var(--border-radius-medium);
	overflow: hidden;
	padding-bottom: 67.5%; /* 40:27 */
	position: relative;
	text-decoration: none;
	transform: translateZ(0); /* fix safari border radius */
	width: 100%;
}

@media (--mq-from-wide) {
	.showcase-rows__item__media-link {
		border-radius: var(--border-radius-large);
	}
}

.showcase-rows__item__media-asset {
	backface-visibility: hidden;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0) scale(1, 1);
	transform-origin: center center;
	transition: transform 320ms ease-out;
	width: 100%;
}

.showcase-rows__item__media-link:focus .showcase-rows__item__media-asset,
.showcase-rows__item__media-link:hover .showcase-rows__item__media-asset {
	transform: translate3d(-50%, -50%, 0) scale(1.1, 1.1);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}
