.showcase-columns {
}

.showcase-columns__row {
	margin-bottom: 2rem;
}

@media (--mq-from-small) {
	.showcase-columns__row {
		display: flex;
		margin-right: -0.5rem;
		margin-left: -0.5rem;
	}
}

@media (--mq-from-wide) {
	.showcase-columns__row {
		margin-bottom: 4.375rem;
	}
}

.showcase-columns__row:first-child {
	margin-top: 2rem;
}

@media (--mq-from-small) {
	.showcase-columns__row:first-child {
		margin-top: 4.625rem;
	}
}

@media (--mq-from-wide) {
	.showcase-columns__row:first-child {
		margin-top: 4.375rem;
	}
}

.showcase-columns__item {
	display: flex;
	flex-direction: column;
	text-align: center;
}

@media (--mq-from-small) {
	.showcase-columns__item {
		flex-basis: calc(50% - 1rem);
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}

.showcase-columns__item__content {
	padding: 1.875rem;
}

@media (--mq-from-wide) {
	.showcase-columns__item__content {
		padding: 3.75rem;
	}
}

.showcase-columns__item__media-wrapper {
	position: relative;
}

.showcase-columns__item__media-wrapper img {
	width: 100%;
}

.showcase-columns__item__media,
.showcase-columns__item__media-link {
	display: block;
	width: 100%;
}

.showcase-columns__item__media-link {
	border-radius: var(--border-radius-large);
	overflow: hidden;
	position: relative;
	text-decoration: none;
	transform: translateZ(0); /* fix safari border radius */
}

.showcase-columns__item__media-asset {
	transition: transform 320ms ease-out;
}

.showcase-columns__item__media-link:focus .showcase-columns__item__media-asset,
.showcase-columns__item__media-link:hover .showcase-columns__item__media-asset {
	transform: scale(1.1);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}

/* center video player */

/* fix safari border radius */
@media (--mq-from-small) {
	.showcase-columns__item__media__aspect-ratio {
		padding-bottom: 67.5%;
	}

	.showcase-columns__item__media__video {
		background-color: var(--color-black);
		border-radius: var(--border-radius-large);
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateZ(0);
	}

	.showcase-columns__item__media__video > .video-embed-player {
		border-radius: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.showcase-columns__item__media__video > .video-embed-player .rounded-media {
		border-radius: 0;
	}
}
