button:not([class]),
.button {
	background-color: rgb(0 0 0 / 0);
	border: 2px solid var(--color-black);
	border-radius: var(--border-radius-xlarge);
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-black);
	display: inline-block;
	font-size: 0.6875rem;
	font-weight: var(--font-weight-regular);
	letter-spacing: 1px;
	padding: 0.4375rem 1.25rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	width: auto;
}

@media (--mq-from-medium) {
	button:not([class]),
	.button {
		padding-top: 0.625rem;
		padding-bottom: 0.625rem;
	}
}

button:not([class]):focus,
button:not([class]):hover,
.button:focus,
.button:hover {
	background-color: rgb(0 0 0 / 1);
	color: var(--color-white);
}

button:not([class]):focus,
.button:focus {
	outline: 0;
}

button:not([class]):hover,
.button:hover {
	cursor: pointer;
}

@media (--mq-up-to-small) {
	.button-group button:not([class]),
	.button-group .button {
		width: 100%;
	}
}

@media (--mq-from-small) {
	.button-group button:not([class]),
	.button-group .button {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0.625rem;
	}
}

@media (--mq-up-to-small) {
	.button-group button:not([class]):not(:last-child),
	.button-group .button:not(:last-child) {
		margin-bottom: 1.25rem;
	}
}

button:not([class]).button--inverted,
.button.button--inverted {
	background-color: rgb(255 255 255 / 0);
	border-color: var(--color-white);
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-white);
}

button:not([class]).button--inverted:focus,
button:not([class]).button--inverted:hover,
.button.button--inverted:focus,
.button.button--inverted:hover {
	background-color: rgb(255 255 255 / 1);
	color: var(--color-black);
}

a.button:focus,
a.button:hover {
	opacity: 1;
}
