.reset-filter {
	color: currentColor;
	text-decoration: none;
}

.reset-filter__icon {
	background-color: var(--color-black);
	border-radius: 50%;
	box-sizing: border-box;
	color: var(--color-white);
	display: inline-block;
	padding: 0.25rem;
	vertical-align: middle;
	width: 1.25rem;
	height: 1.25rem;
}

@media (--mq-from-medium) {
	.reset-filter__icon {
		padding: 0.3125rem;
		width: 1.875rem;
		height: 1.875rem;
	}
}

.reset-filter__icon .icon {
	display: block;
	width: 0.75rem;
	height: 0.75rem;
}

@media (--mq-from-medium) {
	.reset-filter__icon .icon {
		width: 1.25rem;
		height: 1.25rem;
	}
}

.reset-filter__label {
	margin-left: 0.625rem;
	vertical-align: middle;
}
