.agent-finder {
	margin-bottom: 2rem;
}

@media (--mq-from-small) {
	.agent-finder {
		margin-bottom: 5rem;
	}
}

@media (--mq-from-large) {
	.agent-finder {
		margin-bottom: 7rem;
	}
}

.agent-finder__filter {
	border-bottom: 1px solid var(--color-black);
	padding-top: 2rem;
	padding-bottom: 2rem;
	width: 100%;
}

@media (--mq-from-small) {
	.agent-finder__filter {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

@media (--mq-from-large) {
	.agent-finder__filter {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.agent-finder__filter__container {
	display: flex;
	flex-direction: column;
	margin-right: auto;
	margin-left: auto;
	align-items: flex-start;
	width: 100%;
	max-width: 655px;
}

@media (--mq-from-small) {
	.agent-finder__filter__container {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

@media (--mq-up-to-small) {
	.agent-finder__filter__label {
		margin-bottom: 1rem;
	}
}

@media (--mq-from-small) {
	.agent-finder__filter__label {
		margin-right: 1rem;
	}
}

@media (--mq-from-medium) {
	.agent-finder__filter__label {
		margin-right: 1.4375rem;
	}
}

@media (--mq-from-wide) {
	.agent-finder__filter__label {
		margin-right: 1rem;
	}
}

.agent-finder__filter__select {
	appearance: none;
	background-color: var(--color-white);
	background-image: url("../images/arrow-down.png");
	background-position: calc(100% - 1rem) 50%;
	background-repeat: no-repeat;
	background-size: 10px 10px;
	border: 1px solid var(--color-grey-light);
	border-radius: var(--border-radius-small);
	box-shadow: none;
	box-sizing: border-box;
	display: inline-block;
	font-size: 0.75rem;
	line-height: 40px;
	padding: 0 0.375rem;
	height: 40px;

	/* Leave enough room for the select icon on the right */
	padding-right: 2rem;
}

@media (--mq-up-to-small) {
	.agent-finder__filter__select {
		align-self: stretch;
	}
}

@media (--mq-from-small) {
	.agent-finder__filter__select {
		flex-grow: 1;
	}
}

@media (--mq-from-medium) {
	.agent-finder__filter__select {
		border-radius: var(--border-radius-xsmall);
		font-size: 0.875rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
}

@media (--mq-from-medium) and (max-width: 1279px) {
	.agent-finder__filter__select {
		line-height: 30px;
		height: 30px;
	}
}

@media (--mq-from-large) {
	.agent-finder__filter__select {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

.agent-finder__filter__select:focus {
	border-color: var(--color-black);
	outline: 0;
}

.agent-finder__filter__submit {
	appearance: none;
	background: none;
	border: none;
	box-shadow: none;
	font-size: 0.875rem;
	line-height: 1.8571;
	outline: 0;
	padding: 0;
	text-decoration: underline;
	transition: opacity 96ms linear;
}

@media (--mq-up-to-small) {
	.agent-finder__filter__submit {
		margin-top: 1rem;
		align-self: flex-end;
	}
}

@media (--mq-from-small) {
	.agent-finder__filter__submit {
		margin-left: 1.4375rem;
	}
}

@media (--mq-from-wide) {
	.agent-finder__filter__submit {
		margin-left: 1rem;
	}
}

.agent-finder__filter__submit:focus,
.agent-finder__filter__submit:hover {
	opacity: 0.3;
}

.agent-finder__filter__submit:focus {
	outline: 0;
}

.agent-finder__filter__submit:hover {
	cursor: pointer;
}

.agent-finder__results {
	border-bottom: 1px solid var(--color-black);
	margin-bottom: 2rem;
}

.agent-finder__results.agent-finder__results--empty {
	border-bottom: 0;
}

@media (--mq-from-small) {
	.agent-finder__results {
		margin-bottom: 2.5rem;
	}
}

@media (--mq-from-large) {
	.agent-finder__results {
		margin-bottom: 5rem;
	}
}

.agent-finder__results__title {
	padding-top: 2rem;
	padding-bottom: 2rem;
	text-align: center;
}

@media (--mq-from-small) {
	.agent-finder__results__title {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

@media (--mq-from-large) {
	.agent-finder__results__title {
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
	}
}

@media (--mq-from-xlarge) {
	.agent-finder__results__title {
		padding-top: 5.4375rem;
		padding-bottom: 5.4375rem;
	}
}
