.collection-label {
	background-color: var(--color-background-collection-label);
	border-radius: 1rem;
	color: var(--color-white);
	display: inline-block;
	line-height: 1rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	transform: translate3d(0, -1px, 0);
	vertical-align: middle;
	height: 1rem;
}

@media (min-width: 350px) {
	.collection-variations__label .collection-label {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}

.collection-label--end-of-stock {
	--color-background-collection-label: transparent;

	border: 1px solid var(--color-grey);
	color: var(--color-grey);
}

@media (max-width: 350px) {
	.collection-label--end-of-stock {
		font-size: 0.5rem;
		overflow: hidden;
	}
}
