.card-product {
	color: var(--color-black);
	display: block;
	text-decoration: none;
	width: 100%;
}

.card-product:focus {
	outline: 0;
}

.card-product__thumbnail-wrapper {
	border-radius: var(--border-radius-medium);
	margin-bottom: 0.4375rem;
	overflow: hidden;
	position: relative;
	transform: translateZ(0); /* fix safari border radius */
	transition: background-color 144ms linear;
	width: 100%;
}

@media (--mq-from-small) {
	.card-product__thumbnail-wrapper {
		margin-bottom: 0.9375rem;
	}
}

@media (--mq-from-wide) {
	.card-product__thumbnail-wrapper {
		border-radius: var(--border-radius-large);
		margin-bottom: 1.0625rem;
	}
}

.card-product:focus .card-product__thumbnail-wrapper,
.card-product:hover .card-product__thumbnail-wrapper {
	background-color: var(--color-white);
}

.card-product__thumbnail-wrapper.card-product__thumbnail-wrapper--empty {
	background-clip: padding-box;
	background-color: var(--color-white);
}

.card-product__thumbnail {
	backface-visibility: hidden;
	display: block;
	transform: scale(1, 1);
	transform-origin: center center;
	transition: transform 320ms ease-out;
	width: 100%;
	height: auto;
}

.card-product:focus .card-product__thumbnail,
.card-product:hover .card-product__thumbnail {
	transform: scale(1.1, 1.1);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}

.card-product__title {
	line-height: 1.2;
}

.card-product__title__colour-name {
	color: var(--color-grey);
	display: block;
}
