.collection-specs {
	border-top: 1px solid;
	border-bottom: 1px solid;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	padding-top: 3.125rem;
	padding-bottom: 3.125rem;
	justify-content: space-between;
}

.collection-specs__column {
	flex-basis: 100%;
	margin-bottom: 2.5rem;
}

@media (--mq-from-small) {
	.collection-specs__column {
		flex-basis: calc((100% - 1.25rem) / 2);
	}
}

@media (--mq-from-medium) {
	.collection-specs__column {
		flex-basis: calc((100% - (1.25rem * 3)) / 4);
	}
}

@media (--mq-up-to-small) {
	.collection-specs__column.collection-specs__column--small {
		flex-basis: calc((100% - 1.25rem) / 2);
	}
}

.collection-specs__column:last-child {
	margin-bottom: 0;
}

@media (width >= 375px) and (--mq-up-to-small) {
	.collection-specs__button-group {
		display: flex;
		align-items: center;
	}
}

@media (--mq-from-wide) {
	.collection-specs__button-group {
		margin-top: 3rem;
	}
}

@media (width < 375px) {
	.collection-specs__button {
		display: block;
		margin-bottom: 1rem;
	}
}

@media (width >= 375px) and (--mq-up-to-small) {
	.collection-specs__button {
		flex-basis: calc(50% - 0.625rem);
	}
}

@media (--mq-from-small) {
	.collection-specs__button {
		display: block;
		margin-bottom: 1rem;
		max-width: 16.5rem;
	}
}

@media (--mq-from-large) {
	.collection-specs__button {
		margin-bottom: 2.1875rem;
	}
}

@media (width >= 375px) and (--mq-up-to-small) {
	.collection-specs__button + .collection-specs__button {
		margin-left: 1.25rem;
	}
}

.collection-specs__button:last-child {
	margin-bottom: 0;
}
