.site-header {
	background-color: var(--color-white);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: var(--z-index-site-header);
}

.l-styleguide .site-header {
	position: relative;
	top: auto;
	left: auto;
}

.site-header__wrapper {
	display: flex;
	align-items: center;
	position: relative;
	height: var(--site-header-height-small);
}

@media (--mq-up-to-medium) {
	.site-header__wrapper {
		justify-content: flex-end;
	}
}

@media (--mq-from-medium) {
	.site-header__wrapper {
		height: var(--site-header-height-medium);
	}
}

@media (--mq-from-large) {
	.site-header__wrapper {
		height: var(--site-header-height-large);
	}
}

/*
 * Container Layouts — Flex
 */

.site-header__logo-container {
	display: flex;
	flex-basis: calc(100% - 40px);
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
}

@media (--mq-from-medium) {
	.site-header__logo-container {
		justify-content: flex-start;
	}
}

.site-header__meta-container {
	flex-basis: 20px;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (--mq-from-medium) {
	.site-header__meta-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

@media (--mq-from-medium) {
	.site-header__logo-container,
	.site-header__meta-container {
		flex-basis: 22.5%;
	}
}

@media (--mq-from-large) {
	.site-header__logo-container,
	.site-header__meta-container {
		flex-basis: 20%;
	}
}

@media (--mq-up-to-medium) {
	.site-header__meta-container__retailer {
		display: none;
	}
}

@media (--mq-from-medium) {
	.site-header__meta-container__retailer {
		margin-right: 0.9375rem;
	}
}

@media (--mq-from-medium) {
	.site-header__meta-container__retailer__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

@media (--mq-from-medium) {
	.site-header__primary-navigation-container {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (--mq-from-medium) {
	.site-header__language-selection,
	.site-header__professional-navigation {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/*
 * Container Layouts — Visibility
 */
@media (--mq-up-to-medium) {
	.site-header__language-selection,
	.site-header__primary-navigation-container,
	.site-header__professional-navigation {
		display: none;
	}
}

.site-header__mobile-navigation-toggle {
	display: block;
}

@media (--mq-from-medium) {
	.site-header__mobile-navigation-toggle {
		display: none;
	}
}

/*
 * Logo
 *
 * Note: the logo uses inline rendered CSS for its focus- and hoverstate color
 * @see views/partials/navigation/site-header.twig
 *
 */

.site-header__logo {
	display: inline-block;
	text-decoration: none;
	transition: opacity 96ms linear;
	width: auto;
}

.site-header__logo:focus {
	outline: 0;
}

.site-header__logo:focus,
.site-header__logo:hover {
	opacity: 0.7;
}

.site-header__logo .icon {
	display: block;
	width: 132px;
	height: 30px;
}

@media (--mq-from-medium) {
	.site-header__logo .icon {
		width: 175px;
		height: 40px;
	}
}

@media (--mq-from-wide) {
	.site-header__logo .icon {
		width: 220px;
		height: 50px;
	}
}

/*
 * Meta Navigation
 *
 * Note: links use inline rendered CSS for their focus- and hoverstate color
 * @see views/partials/navigation/site-header.twig
 *
 */

.site-header__professional-navigation {
	color: currentColor;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 0.9375rem 0 0;
	padding: 0;
	justify-content: center;
	align-items: center;
}

@media (--mq-from-medium) {
	.site-header__professional-navigation {
		display: flex;
	}
}

@media (--mq-from-large) {
	.site-header__professional-navigation {
		margin-right: 1.375rem;
	}
}

.site-header__professional-navigation__item {
	margin-right: 0.9375rem;
}

@media (--mq-from-large) {
	.site-header__professional-navigation__item {
		margin-right: 1.375rem;
	}
}

.site-header__professional-navigation__item:last-child {
	margin-right: 0;
}

.site-header__professional-navigation__link {
	color: currentColor;
	font-size: 0.9375rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.2667;
	text-decoration: none;
	transition: color 96ms linear;
}

@media (--mq-from-large) {
	.site-header__professional-navigation__link {
		font-size: 1rem;
		line-height: 1.1875;
	}
}

.site-header__professional-navigation__link:focus {
	outline: 0;
}

/*
 * Language Selection
 *
 * Note: toggle uses inline rendered CSS for its focus- and hoverstate color
 * Note: language dropdpwn uses inline rendered CSS for its background-color
 * @see views/partials/navigation/site-header.twig
 *
 */
@media (--mq-from-medium) {
	.site-header__language-selection {
		padding-right: 0.75rem;
	}
}

@media (--mq-from-large) {
	.site-header__language-selection {
		padding-right: 1.125rem;
	}
}

.site-header__language-selection__checkbox {
	display: none;
}

.site-header__language-selection__toggle {
	color: currentColor;
	font-size: 0.9375rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.2667;
	position: relative;
	text-decoration: none;
	text-transform: capitalize;
	transition: color 96ms linear;
}

.site-header__language-selection__toggle:focus {
	outline: 0;
}

.site-header__language-selection__toggle:hover {
	cursor: pointer;
}

.site-header__language-selection__toggle > span {
	box-sizing: content-box;
	display: inline-block;
	padding-right: 0.625rem;
	position: relative;
}

.site-header__language-selection__toggle > span::after {
	border-top: 5px solid currentColor;
	border-right: 3px solid transparent;
	border-left: 3px solid transparent;
	content: "";
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 0;
	height: 0;
}

.site-header__language-selection__dropdown {
	box-sizing: border-box;
	padding: 2rem;
	position: absolute;
	top: 100%;
	right: 0;
	width: 190px;
	z-index: 9;
}

@media (--mq-from-wide) {
	.site-header__language-selection__dropdown {
		padding-right: 3.625rem;
		padding-left: 3.625rem;
		width: 230px;
	}
}

.site-header__language-selection__checkbox:not(:checked) ~ .site-header__language-selection__dropdown {
	display: none;
}

.site-header__language-selection__dropdown-arrow {
	pointer-events: none;
	position: absolute;
	top: -5px;
	right: 2rem;
	user-select: none;
	width: 12px;
	height: 6px;
}

@media (--mq-from-large) {
	.site-header__language-selection__dropdown-arrow {
		right: 3.625rem;
	}
}

.site-header__language-selection__dropdown-arrow::after {
	border-right: 6px solid transparent;
	border-bottom: 6px solid currentColor;
	border-left: 6px solid transparent;
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	width: 0;
	height: 0;
}

/*
 * Language List
 */

.site-header__language-list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: right;
}

.site-header__language-list__item {
	margin-bottom: 0.75rem;
}

.site-header__language-list__item:last-child {
	margin-bottom: 0;
}

.site-header__language-list__link {
	color: var(--color-black);
	font-size: 0.9375rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.2667;
	opacity: 0.3;
	text-decoration: none;
	transition: opacity 96ms linear;
}

@media (--mq-from-large) {
	.site-header__language-list__link {
		font-size: 1rem;
		line-height: 1.1875;
	}
}

.site-header__language-list__item--active .site-header__language-list__link,
.site-header__language-list__link:focus,
.site-header__language-list__link:hover {
	opacity: 1;
}

.site-header__language-list__link:focus {
	outline: 0;
}

/*
 * Mobile Menu Toggle
 */

.site-header__mobile-navigation-toggle {
	color: currentColor;
	position: relative;
	width: 17px;
	height: 14px;
}

.site-header__mobile-navigation-toggle svg {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}

.mobile-navigation__checkbox:not(:checked) ~ .site-header .site-header__mobile-navigation-toggle.site-header__mobile-navigation-toggle--close-menu,
.mobile-navigation__checkbox:checked ~ .site-header .site-header__mobile-navigation-toggle.site-header__mobile-navigation-toggle--open-menu {
	display: none;
}

@media (--mq-up-to-medium) {
	.mobile-navigation__checkbox:not(:checked) ~ .site-header .site-header__mobile-navigation-toggle.site-header__mobile-navigation-toggle--open-menu,
	.mobile-navigation__checkbox:checked ~ .site-header .site-header__mobile-navigation-toggle.site-header__mobile-navigation-toggle--close-menu {
		display: block;
	}
}
