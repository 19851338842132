.arrow-icon {
	background-color: var(--color-white);
	border-radius: 50%;
	display: inline-block;
	position: relative;
}

.arrow-icon::after {
	content: "";
}

a:hover .arrow-icon::after,
a:focus .arrow-icon::after {
	opacity: 0;
}

.arrow-icon .arrow-icon__hover-triangle,
.arrow-icon::after {
	border-color: transparent;
	border-style: solid;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: opacity ease-in-out 96ms;
	width: 0;
	height: 0;
}

.arrow-icon--back {
	width: 20px;
	height: 20px;
}

@media (--mq-from-medium) {
	.arrow-icon--back {
		width: 25px;
		height: 25px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--back {
		width: 30px;
		height: 30px;
	}
}

.arrow-icon--back .arrow-icon__hover-triangle,
.arrow-icon--back::after {
	border-width: 4px 8px 4px 0;
	border-right-color: var(--color-black);
	transform: translate3d(-62.5%, -50%, 0);
}

@media (--mq-from-medium) {
	.arrow-icon--back .arrow-icon__hover-triangle,
	.arrow-icon--back::after {
		border-width: 5px 10px 5px 0;
	}
}

@media (--mq-from-large) {
	.arrow-icon--back .arrow-icon__hover-triangle,
	.arrow-icon--back::after {
		border-width: 5px 10px 5px 0;
	}
}

.arrow-icon--forth {
	width: 20px;
	height: 20px;
}

@media (--mq-from-medium) {
	.arrow-icon--forth {
		width: 25px;
		height: 25px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--forth {
		width: 30px;
		height: 30px;
	}
}

.arrow-icon--forth .arrow-icon__hover-triangle,
.arrow-icon--forth::after {
	border-width: 4px 0 4px 8px;
	border-left-color: var(--color-black);
	transform: translate3d(-37.5%, -50%, 0);
}

@media (--mq-from-medium) {
	.arrow-icon--forth .arrow-icon__hover-triangle,
	.arrow-icon--forth::after {
		border-width: 5px 0 5px 10px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--forth .arrow-icon__hover-triangle,
	.arrow-icon--forth::after {
		border-width: 5px 0 5px 10px;
	}
}

.arrow-icon--play {
	width: 40px;
	height: 40px;
}

@media (--mq-from-medium) {
	.arrow-icon--play {
		width: 84px;
		height: 84px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--play {
		width: 128px;
		height: 128px;
	}
}

.arrow-icon--play .arrow-icon__hover-triangle,
.arrow-icon--play::after {
	border-width: 7px 0 7px 14px;
	border-left-color: var(--color-black);
	transform: translate3d(-37.5%, -50%, 0);
}

@media (--mq-from-medium) {
	.arrow-icon--play .arrow-icon__hover-triangle,
	.arrow-icon--play::after {
		border-width: 15px 0 15px 30px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--play .arrow-icon__hover-triangle,
	.arrow-icon--play::after {
		border-width: 24px 0 24px 48px;
	}
}

.arrow-icon--small.arrow-icon--play {
	width: 40px;
	height: 40px;
}

@media (--mq-from-medium) {
	.arrow-icon--small.arrow-icon--play {
		width: 56px;
		height: 56px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--small.arrow-icon--play {
		width: 64px;
		height: 64px;
	}
}

.arrow-icon--small.arrow-icon--play .arrow-icon__hover-triangle,
.arrow-icon--small.arrow-icon--play::after {
	border-width: 7px 0 7px 14px;
	border-left-color: var(--color-black);
	transform: translate3d(-37.5%, -50%, 0);
}

@media (--mq-from-medium) {
	.arrow-icon--small.arrow-icon--play .arrow-icon__hover-triangle,
	.arrow-icon--small.arrow-icon--play::after {
		border-width: 10px 0 10px 20px;
	}
}

@media (--mq-from-large) {
	.arrow-icon--small.arrow-icon--play .arrow-icon__hover-triangle,
	.arrow-icon--small.arrow-icon--play::after {
		border-width: 12px 0 12px 24px;
	}
}

.arrow-icon__hover-triangle {
	opacity: 0;
}

a:hover .arrow-icon__hover-triangle,
a:focus .arrow-icon__hover-triangle {
	opacity: 1;
}
