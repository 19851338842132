/*
 * Meta Navigation
 *
 * Note: uses inline rendered CSS for background-color
 * @see views/partials/mobile-navigation.twig
 *
 */

.mobile-navigation {
	-webkit-overflow-scrolling: touch;
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;
	position: fixed;
	top: var(--site-header-height-small);
	left: 0;
	width: 100%;
	height: calc(100% - var(--site-header-height-small));
	z-index: var(--z-index-mobile-navigation);
}

@media (--mq-up-to-medium) {
	.mobile-navigation__checkbox:checked ~ .mobile-navigation {
		display: block;
	}
}

.l-styleguide .mobile-navigation {
	position: relative;
	top: auto;
	left: auto;
	height: calc(100vh - var(--site-header-height-small));
}

.mobile-navigation__inner {
	width: 100%;
	min-height: 100%;
}

.mobile-navigation__section {
	box-sizing: border-box;
	padding: 1.75rem 1.25rem;
	width: 100%;
}

@media (min-width: 375px) {
	.mobile-navigation__section {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

.mobile-navigation__section:first-child {
	padding-top: 1.25rem;
}

@media (min-width: 375px) {
	.mobile-navigation__section:first-child {
		padding-top: 1.625rem;
	}
}

.mobile-navigation__section:not(:last-child) {
	border-bottom: 1px solid rgb(0 0 0 / 0.25);
}

.mobile-navigation__section > p:not([class]) {
	opacity: 0.45;
	text-align: center;
}

.mobile-navigation__language-list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
}

.mobile-navigation__language-list:not(:last-child) {
	margin-bottom: 1.125rem;
}

.mobile-navigation__language-list__item {
	margin-right: 0.3125rem;
	margin-left: 0.3125rem;
}

.mobile-navigation__language-list__link {
	color: currentColor;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
	line-height: 2.5;
	text-decoration: none;
}

.mobile-navigation__language-list__item--active .mobile-navigation__language-list__link {
	opacity: 0.3;
}

.mobile-navigation__language-list__link:focus {
	outline: 0;
}

.mobile-navigation__meta-navigation {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.mobile-navigation__meta-navigation__item {
	margin-bottom: 0.6875rem;
	text-align: center;
	width: 100%;
}

.mobile-navigation__meta-navigation__item:last-child {
	margin-bottom: 0;
}

.mobile-navigation__meta-navigation__link {
	color: currentColor;
	font-size: 1rem;
	line-height: 1.875;
	text-decoration: none;
}

@media (--mq-up-to-medium) {
	.mobile-navigation__meta-navigation__item.current-menu-item .mobile-navigation__meta-navigation__link {
		opacity: 0.3;
	}
}

.mobile-navigation__meta-navigation__link:focus {
	outline: 0;
}
