/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
body {
	background-color: var(--color-white);
	color: var(--color-black);
	margin: 0;
	padding: 0;
}

body[has-open-overlay] {
	overflow: hidden;
}

textarea {
	resize: none;
}

hr {
	background-color: currentColor;
	border: none;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
}

th,
td {
	border: 1px solid var(--color-grey-light);
}

table {
	border-collapse: collapse;
	margin: 1.25rem 0;
	width: 100%;
	max-width: 100%;
}

@media (--mq-from-medium) {
	table {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	padding: 1.25rem;
	text-align: left;
}

th {
	font-weight: var(--font-weight-medium);
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	list-style: none;
	margin: 0 0 1.25rem 0;
	padding-left: 1.25rem;
}

@media (--mq-from-medium) {
	ol:not([class]),
	ul:not([class]) {
		margin-bottom: 1.5rem;
	}
}

ol:not([class]) ol:not([class]),
ol:not([class]) ul:not([class]),
ul:not([class]) ol:not([class]),
ul:not([class]) ul:not([class]) {
	padding-left: 1rem;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ol:not([class]) {
	counter-reset: ordered-list;
}

li:not([class]) {
	counter-increment: ordered-list;
	position: relative;
}

ol:not([class]) li:not([class]) {
	counter-reset: ordered-sublist;
}

ul:not([class]) li:not([class])::before {
	content: "• ";
}

ol:not([class]) li:not([class])::before {
	content: counter(ordered-list) ". ";
}

ol:not([class]) ol:not([class]) li:not([class])::before {
	content: counter(ordered-list, lower-alpha) ") ";
	counter-increment: ordered-sublist;
}

[hidden]:not([hidden="until-found"]) {
	display: none !important;
}

@media (--mq-up-to-xsmall) {
	.g-recaptcha {
		transform: scale(0.85);
		transform-origin: 0 0;
	}
}
