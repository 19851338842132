.navigation-list {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.navigation-list__link {
	color: currentColor;
	font-weight: var(--font-weight-medium);
	text-decoration: none;
	transition: opacity 96ms linear;
}

.navigation-list__link:focus,
.navigation-list__link:hover {
	opacity: 0.3;
}
