.search {
	margin-bottom: 1.875rem;
}

@media (--mq-from-medium) {
	.search {
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-large) {
	.search {
		margin-bottom: 2.5rem;
	}
}

.search__container {
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

@media (--mq-from-small) {
	.search__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media (--mq-from-medium) {
	.search__container {
		padding-top: 1.5625rem;
		padding-bottom: 1.5625rem;
	}
}

@media (--mq-from-large) {
	.search__container {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

.search__label {
	display: inline-block;
	margin-right: 1rem;
}

@media (--mq-from-medium) {
	.search__label {
		margin-right: 1.4375rem;
	}
}

@media (--mq-from-wide) {
	.search__label {
		margin-right: 1rem;
	}
}

@media (--mq-up-to-small) {
	.search__count {
		margin-top: 1.25rem;
	}
}

.search__input-container {
	flex-basis: 55%;
}

.search__input {
	background: var(--color-white);
	border: 1px solid var(--color-grey-light);
	border-radius: var(--border-radius-small);
	box-shadow: none;
	box-sizing: border-box;
	display: inline-block;
	font-size: 0.75rem;
	line-height: 40px;
	padding: 0 0.375rem;
	max-width: 158px;
	height: 40px;
}

@media (--mq-from-medium) {
	.search__input {
		border-radius: var(--border-radius-xsmall);
		font-size: 0.875rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
}

@media (--mq-from-medium) and (max-width: 1279px) {
	.search__input {
		line-height: 30px;
		height: 30px;
	}
}

@media (min-width: 1280px) {
	.search__input {
		width: 220px;
	}
}

@media (--mq-from-large) {
	.search__input {
		padding-right: 1rem;
		padding-left: 1rem;
		width: 323px;
	}
}

.search__input:focus {
	border-color: var(--color-black);
	outline: 0;
}

.search__submit {
	appearance: none;
	background: none;
	border: none;
	box-shadow: none;
	display: inline-block;
	font-size: 0.875rem;
	line-height: 1.8571;
	margin-left: 1.4375rem;
	outline: 0;
	padding: 0;
	text-decoration: underline;
	transition: opacity 96ms linear;
}

@media (--mq-from-wide) {
	.search__submit {
		margin-left: 1rem;
	}
}

.search__submit:focus,
.search__submit:hover {
	opacity: 0.3;
}

.search__submit:focus {
	outline: 0;
}

.search__submit:hover {
	cursor: pointer;
}

.search__reset {
	flex-basis: 45%;
	text-align: right;
}

@media (--mq-up-to-xsmall) {
	.search__reset {
		margin-top: 1.9375rem; /* visually aligned with search input text */
	}
}

@media (--mq-from-xsmall) and (--mq-up-to-small) {
	.search__reset {
		margin-top: 0.375rem; /* visually aligned with search input text */
	}
}
