.gallery-a {
	box-sizing: border-box;
	color: var(--color-white);
	display: block;
	position: relative;
	width: 100%;
}

.gallery-a.gallery-a--spacer-bottom {
	margin-bottom: 0.9375rem;
}

@media (--mq-from-medium) {
	.gallery-a.gallery-a--spacer-bottom {
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-large) {
	.gallery-a.gallery-a--spacer-bottom {
		margin-bottom: 2rem;
	}
}

.gallery-a.gallery-a--spacer-top {
	margin-top: 0.9375rem;
}

@media (--mq-from-medium) {
	.gallery-a.gallery-a--spacer-top {
		margin-top: 1.25rem;
	}
}

@media (--mq-from-large) {
	.gallery-a.gallery-a--spacer-top {
		margin-top: 2rem;
	}
}

@media (--mq-from-small) {
	.gallery-a--wrapper.wrapper--xsmall .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--xsmall .gallery-a__slide {
		min-height: calc(600px / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-wide) {
	.gallery-a--wrapper.wrapper--xsmall .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--xsmall .gallery-a__slide {
		min-height: calc(800px / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-small) {
	.gallery-a--wrapper.wrapper--small .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--small .gallery-a__slide {
		min-height: calc((100vw - 2.5rem - 2.5rem) / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-medium) {
	.gallery-a--wrapper.wrapper--small .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--small .gallery-a__slide {
		min-height: calc((100vw - 6.25rem - 6.25rem) / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-large) {
	.gallery-a--wrapper.wrapper--small .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--small .gallery-a__slide {
		min-height: calc(1170px / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-small) {
	.gallery-a--wrapper.wrapper--medium .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--medium .gallery-a__slide {
		min-height: calc((100vw - 2.5rem - 2.5rem) / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-medium) {
	.gallery-a--wrapper.wrapper--medium .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--medium .gallery-a__slide {
		min-height: calc((100vw - 6.25rem - 6.25rem) / 100 * 56.25); /* 16:9 */
	}
}

@media (--mq-from-large) {
	.gallery-a--wrapper.wrapper--medium .gallery-a__slides-wrapper,
	.gallery-a--wrapper.wrapper--medium .gallery-a__slide {
		min-height: calc(1580px / 100 * 56.25); /* 16:9 */
	}
}

.gallery-a__slides-wrapper {
	border-radius: var(--border-radius-large);
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
	min-height: 75vw; /* 4:3 */
	z-index: 2;
}

@media (--mq-from-small) {
	.gallery-a__slides-wrapper {
		min-height: 68.75vw; /* 16:11 */
	}
}

@media (--mq-from-large) {
	.gallery-a__slides-wrapper {
		min-height: 50vw; /* 2:1 */
	}
}

.gallery-a__slide {
	display: block;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear 48ms;
	width: 100%;
	min-height: 75vw; /* 4:3 */
	will-change: transform;
}

@media (--mq-from-small) {
	.gallery-a__slide {
		min-height: 68.75vw; /* 16:11 */
	}
}

@media (--mq-from-large) {
	.gallery-a__slide {
		min-height: 50vw; /* 2:1 */
	}
}

.gallery-a__toggle:checked + .gallery-a__slide {
	opacity: 1;
	pointer-events: all;
	transition: opacity 96ms linear;
}

.gallery-a__slide.gallery-a__slide--has-content::before {
	background: linear-gradient(to bottom, rgb(149 149 149 / 0.5) 0%, rgb(149 149 149 / 0) 66%);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.gallery-a__asset {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	max-width: none;
	height: 100%;
	z-index: 1;
}

.gallery-a__content {
	display: block;
	padding-top: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 4rem;
	padding-left: 1.25rem;
	position: relative;
	width: 100%;
	max-width: 360px;
	z-index: 3;
}

@media (--mq-from-small) {
	.gallery-a__content {
		padding-bottom: 5rem;
	}
}

@media (--mq-from-large) {
	.gallery-a__content {
		padding-top: 2.5rem;
		padding-right: 3.75rem;
		padding-bottom: 7.5rem;
		padding-left: 3.75rem;
	}
}

.gallery-a__navigation {
	box-sizing: border-box;
	display: flex;
	font-weight: 500;
	padding-right: 1.25rem;
	padding-bottom: 1.25rem;
	padding-left: 1.25rem;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 3;
}

@media (--mq-from-small) {
	.gallery-a__navigation {
		padding-right: 1.875rem;
		padding-bottom: 1.875rem;
		padding-left: 1.875rem;
		justify-content: flex-end;
	}
}

@media (--mq-from-large) {
	.gallery-a__navigation {
		padding-right: 3.75rem;
		padding-bottom: 3.75rem;
		padding-left: 3.75rem;
	}
}

@media (--mq-from-small) {
	.gallery-a__navigation .gallery-a--wrapper {
		justify-content: space-between;
	}
}

@media (--mq-from-large) {
	.gallery-a__navigation .gallery-a--wrapper {
		justify-content: flex-end;
	}
}

.gallery-a__arrows-container {
	display: flex;
}

@media (--mq-from-small) {
	.gallery-a__arrows-container {
		margin-left: 1.25rem;
	}
}

.gallery-a__arrow {
	cursor: pointer;
	display: inline-block;
}

.gallery-a__arrow + .gallery-a__arrow {
	margin-left: 0.3125rem;
}

@media (--mq-from-small) {
	.gallery-a__arrow + .gallery-a__arrow {
		margin-left: 1.25rem;
	}
}

.gallery-a__arrow__icon {
	pointer-events: none;
}

.gallery-a__background-color-top,
.gallery-a__background-color-bottom {
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: 1;
}

.gallery-a__background-color-top {
	top: 0;
}

.gallery-a__background-color-bottom {
	bottom: 0;
}
