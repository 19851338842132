.accordion-columns {
}

@media (--mq-from-small) {
	.accordion-columns {
		display: flex;
		justify-content: center;
	}
}

/* #region Item */
@media (--mq-up-to-small) {
	.accordion-columns__item {
		border-bottom: 1px solid var(--color-grey-light);
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.accordion-columns__item:first-child {
		padding-top: 0;
	}
}

@media (--mq-from-small) {
	.accordion-columns__item {
		flex-basis: calc((100% - 8rem) / 3);
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 1rem;
		margin-left: 1rem;
	}
}
/* #endregion Item */

/* #region Label */
@media (--mq-up-to-small) {
	.accordion-columns__label {
		display: flex;
		justify-content: space-between;
		pointer-events: none;
	}
}

.accordion-columns__trigger[aria-expanded="false"] .accordion-columns__label__toggle::before {
	content: attr(data-open);
}

.accordion-columns__trigger[aria-expanded="true"] .accordion-columns__label__toggle::before {
	content: attr(data-close);
}

@media (--mq-from-small) {
	.accordion-columns__label__toggle {
		display: none;
	}
}

@media (--mq-up-to-small) {
	.accordion-columns__label__title {
		margin-bottom: 0;
	}
}
/* #endregion Label */

.accordion-columns__trigger {
	/* button reset */
	appearance: none;
	background: none;
	border-color: transparent;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	/* button reset */

	cursor: default;
	font-size: 0.875rem;
	line-height: 1.8571;
	width: 100%;
}

@media (--mq-up-to-small) {
	.accordion-columns__content-wrapper {
		padding-top: 1.25rem;
	}
}

/* #region Panel */
html:not(.no-js, .js-loading-error) .accordion-columns__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion-columns__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion-columns__panel--open {
	display: none;
}

@media (--mq-from-small) {
	.accordion-columns__panel--closed {
		display: none;
	}

	.accordion-columns__panel--open {
		display: block;
	}
}
/* #endregion Panel */
