/*
 * This layouts contains the (1) main content and the (2) site footer.
 * It make sure the footer always sticks to the bottom.
 */

.l-site-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 100vh;
}

/* Only if there's a site-header (and if it sits on the same level) */

.site-header ~ .l-site-container {
	box-sizing: border-box;
	padding-top: var(--site-header-height-small);
}

@media (--mq-from-medium) {
	.site-header ~ .l-site-container {
		padding-top: var(--site-header-height-medium);
	}
}

@media (--mq-from-large) {
	.site-header ~ .l-site-container {
		padding-top: var(--site-header-height-large);
	}
}

/* (1) */
.l-site-container__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	width: 100%;
}

/* (2) */
.l-site-container__footer {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
}
