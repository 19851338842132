.card {
	color: var(--color-black);
	display: block;
	text-decoration: none;
	width: 100%;
}

.card:focus {
	outline: 0;
}

.card__thumbnail-wrapper {
	border-radius: var(--border-radius-medium);
	margin-bottom: 0.4375rem;
	overflow: hidden;
	padding-bottom: 68.75%; /* 16:11 */
	position: relative;
	transform: translateZ(0); /* fix safari border radius */
	transition: background-color 144ms linear;
	width: 100%;
}

@media (--mq-from-small) {
	.card__thumbnail-wrapper {
		margin-bottom: 0.9375rem;
	}
}

@media (--mq-from-wide) {
	.card__thumbnail-wrapper {
		border-radius: var(--border-radius-large);
		margin-bottom: 1.0625rem;
	}
}

.card:focus .card__thumbnail-wrapper,
.card:hover .card__thumbnail-wrapper {
	background-color: var(--color-grey);
}

.card__thumbnail-wrapper.card__thumbnail-wrapper--empty {
	background-clip: padding-box;
	background-color: var(--color-grey-light);
}

.card__thumbnail {
	backface-visibility: hidden;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0) scale(1, 1);
	transform-origin: center center;
	transition: transform 320ms ease-out;
	width: 100%;
}

.card:focus .card__thumbnail,
.card:hover .card__thumbnail {
	transform: translate3d(-50%, -50%, 0) scale(1.1, 1.1);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}

.card__title {
	line-height: 1.2;
}

.card__title__colour-name {
	color: var(--color-grey);
	display: block;
}
