/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.filter-products {
}

@media (--mq-up-to-medium) {
	.filter-products {
		margin-bottom: 2.25rem;
	}
}

/* region Header */
.filter-products__header {
	text-align: center;
}
/* endregion Header */

/* region Results */
.filter-products__results {
	margin-bottom: 0.75rem;
}

.filter-products__results p {
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.88;
}
/* endregion Results */

/* region Mobile Toggle */
.filter-products__toggle {
	display: block;
}

@media (--mq-from-medium) {
	.filter-products__toggle {
		display: none;
	}
}

.filter-products[show-filters] .filter-products__toggle span[filter-products-open],
.filter-products .filter-products__toggle span[filter-products-close] {
	display: none;
}

.filter-products[show-filters] .filter-products__toggle span[filter-products-close] {
	display: inline;
}
/* endregion Mobile Toggle */

/* region Reset filters */
.filter-products__reset {
	margin-top: 0.9375rem;
}
/* endregion Reset filters */

/* region Label */
.filter-products__label {
	letter-spacing: 1.58px;
}
/* endregion Label */

/* region Main */
@media (--mq-up-to-medium) {
	.filter-products__main {
		display: none;
	}

	.filter-products[show-filters] .filter-products__main {
		display: block;
	}
}
/* endregion Main */

/* region Categories Icons */
.filter-products__categories {
	margin-top: 1.25rem;
}

.filter-products__categories__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	justify-content: space-between;
	align-items: center;
	max-width: 13.125rem;
}

.filter-products__categories__list__item {
	display: flex;
	flex-basis: 6.25rem;
	flex-grow: 0;
	flex-shrink: 0;
	margin-bottom: 1rem;
	align-items: center;
	height: 5rem;
}

.filter-products__categories__link {
	color: var(--color-grey);
	display: block;
	text-align: center;
	text-decoration: none;
	transition: color 96ms linear;
	width: 100%;
}

.filter-products__categories__link:any-link:hover,
.filter-products__categories__link--active {
	color: var(--color-black);
}

.filter-products__categories__icon {
	display: block;
}

.filter-products__categories__icon svg {
	display: block;
	margin: 0 auto;
	max-width: 5.625rem;
	max-height: 3.75rem;
}
/* endregion Categories Icons */

/* region Search */
.filter-products .search__input {
	width: 100%;
	max-width: 100%;
}
/* endregion Search */

/* region List */
.filter-products__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.filter-products__list__link {
	color: var(--color-grey);
	display: block;
	line-height: 1.25rem;
	margin-bottom: 0.625rem;
	padding-left: 2rem;
	position: relative;
	text-decoration: none;
}

.filter-products__list__link:any-link {
	color: var(--color-black);
}

.filter-products__list__link::before,
.filter-products__list__link::after {
	box-sizing: border-box;
	content: "";
	display: block;
	pointer-events: none;
	position: absolute;
}

.filter-products__list__link::before {
	border: solid 1px currentColor;
	top: 0.1875rem;
	left: 0;
	width: 0.9375rem;
	height: 0.9375rem;
}

@media (--mq-from-wide) {
	.filter-products__list__link::before {
		top: 0;
		width: 1.25rem;
		height: 1.25rem;
	}
}

.filter-products__list__link--active::after {
	background-color: currentColor;
	border-radius: 100%;
	top: 0.3125rem;
	left: 0.125rem;
	width: 0.6875rem;
	height: 0.6875rem;
}

@media (--mq-from-wide) {
	.filter-products__list__link--active::after {
		top: 0.1875rem;
		left: 0.1875rem;
		width: 0.875rem;
		height: 0.875rem;
	}
}
/* endregion List */

/* region Color filter */
.filter-products__color {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filter-products__color-swatch {
	background-color: var(--color-black);
	border: solid 1px #ebebeb;
	border-radius: 50%;
	box-sizing: border-box;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
}
/* endregion Color filter */

/* #region Filter Accordion */
.filter-products__accordion {
}

.filter-products__accordion__item {
	border-bottom: solid 1px var(--color-black);
	padding-top: 1.875rem;
	padding-bottom: 1.875rem;
}

.filter-products__accordion__heading {
	margin: 0;
}

.filter-products__accordion__trigger {
	appearance: none;
	background: transparent;
	border: none;
	border-radius: 0;
	box-sizing: border-box;
	cursor: default;
	display: flex;
	padding: 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

html:not(.no-js, .js-loading-error) .filter-products__accordion__trigger:is(:hover, :focus) {
	cursor: pointer;
}

html:is(.no-js, .js-loading-error) .filter-products__accordion__trigger {
	display: none;
}

.filter-products__accordion__trigger span {
	pointer-events: none;
}

.filter-products__accordion__trigger__icon {
	display: block;
	position: relative;
	transition: transform 160ms ease-in-out;
	width: 10px;
	height: 10px;
}

.filter-products__accordion__trigger__icon::before,
.filter-products__accordion__trigger__icon::after {
	background-color: var(--color-black);
	content: "";
	position: absolute;
}

.filter-products__accordion__trigger__icon::before {
	top: 5px;
	left: 0;
	width: 11px;
	height: 1px;
}

.filter-products__accordion__trigger__icon::after {
	top: 0;
	left: 5px;
	width: 1px;
	height: 11px;
}

.filter-products__accordion__trigger[aria-expanded="true"] .filter-products__accordion__trigger__icon {
	transform: rotate(45deg);
}

.filter-products__accordion__panel {
	padding-top: 1.25rem;
}

html:not(.no-js, .js-loading-error) .filter-products__accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .filter-products__accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
