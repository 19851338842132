.instagram-doormat {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}

@media (--mq-from-large) {
	.instagram-doormat {
		margin-top: 5rem;
		margin-bottom: 5rem;
	}
}

.instagram-doormat__title {
	display: block;
	text-align: center;
}

.instagram-doormat__username {
	color: currentColor;
	font-weight: var(--font-weight-regular);
	text-decoration: none;
	transition: opacity 96ms linear;
}

.instagram-doormat__username:focus,
.instagram-doormat__username:hover {
	opacity: 0.3;
}

.instagram-doormat__posts {
	display: flex;
	flex-wrap: wrap;
	margin-top: 1.875rem;
	justify-content: center;
	align-items: center;
}

@media (--mq-from-medium) {
	.instagram-doormat__posts {
		margin-top: 2rem;
	}
}

@media (--mq-from-large) {
	.instagram-doormat__posts {
		margin-top: 4.375rem;
	}
}

.instagram-doormat__post {
	flex-basis: calc((100% - 1.25rem) / 2);
}

@media (--mq-up-to-small) {
	.instagram-doormat__post {
		margin-top: 1.25rem;
	}
}

@media (--mq-from-small) {
	.instagram-doormat__post {
		flex-basis: calc((100% - 3 * 1.25rem) / 4);
	}
}

@media (--mq-from-large) {
	.instagram-doormat__post {
		flex-basis: calc((100% - 3 * 3.125rem) / 4);
	}
}

@media (--mq-up-to-small) {
	.instagram-doormat__post:nth-child(even) {
		margin-left: 1.25rem;
	}
}

@media (--mq-from-small) {
	.instagram-doormat__post:not(:last-child) {
		margin-right: 1.25rem;
	}
}

@media (--mq-from-large) {
	.instagram-doormat__post:not(:last-child) {
		margin-right: 3.125rem;
	}
}
