.collection-description {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	text-align: center;
}

.collection-description__inner {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	width: 100%;
	max-width: 655px;
}

@media (--mq-from-wide) {
	.collection-description__inner {
		max-width: 768px;
	}
}

@media (--mq-from-large) {
	.collection-description__inner {
		max-width: 960px;
	}
}
