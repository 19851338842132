.l-error {
	box-sizing: border-box;
	display: flex;
	padding-top: 2rem;
	padding-bottom: 2rem;
	justify-content: center;
	align-items: center;
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	min-height: 100%;
}

.l-error__wrapper {
	width: calc(100% - 2.5rem);
	max-width: 383px;
}

.l-error__logo {
	display: inline-block;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
}

@media (--mq-from-small) {
	.l-error__logo {
		margin-bottom: 3.125rem;
	}
}

@media (--mq-from-wide) {
	.l-error__logo {
		margin-bottom: 6.25rem;
	}
}

.l-error__logo .icon {
	display: block;
	width: 132px;
	height: 30px;
}

@media (--mq-from-medium) {
	.l-error__logo .icon {
		width: 175px;
		height: 40px;
	}
}

@media (--mq-from-wide) {
	.l-error__logo .icon {
		width: 220px;
		height: 50px;
	}
}

.l-error__title {
	margin-top: 0;
}
