.pagination {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 4.6875rem 0;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (--mq-from-large) {
	.pagination {
		padding-top: 8.125rem;
		padding-bottom: 8.125rem;
	}
}

.pagination__link-container {
	flex-basis: 30px;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (--mq-from-large) {
	.pagination__link-container {
		flex-basis: 40px;
	}
}

.pagination__link {
	background-color: var(--color-white);
	border-radius: 50%;
	color: var(--color-grey-light);
	display: block;
	position: relative;
	width: 30px;
	height: 30px;
}

@media (--mq-from-large) {
	.pagination__link {
		width: 40px;
		height: 40px;
	}
}

.pagination__link:not(.pagination__link--active) {
	pointer-events: none;
}

.pagination__link:not(.pagination__link--active):hover {
	cursor: default;
}

.pagination__link.pagination__link--active {
	box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 0.25);
	color: var(--color-black);
	opacity: 1;
}

.pagination__link .pagination__link__arrow,
.pagination__link::after {
	border-color: transparent;
	border-style: solid;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: opacity 96ms linear;
	width: 0;
	height: 0;
}

.pagination__link::after {
	content: "";
}

.pagination__link:focus::after,
.pagination__link:hover::after {
	opacity: 0;
}

.pagination__link.pagination__link--previous .pagination__link__arrow,
.pagination__link.pagination__link--previous::after {
	border-top: 6px solid transparent;
	border-right: 10px solid currentColor;
	border-bottom: 6px solid transparent;
	transform: translate(calc(-50% - 3px), -50%);
}

.pagination__link.pagination__link--next .pagination__link__arrow,
.pagination__link.pagination__link--next::after {
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 10px solid currentColor;
	transform: translate(calc(-50% + 3px), -50%);
}

.pagination__link__label {
	display: none;
}

.pagination__link__arrow {
	opacity: 0;
}

.pagination__link:focus .pagination__link__arrow,
.pagination__link:hover .pagination__link__arrow {
	opacity: 1;
}

.pagination__pages-container {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	margin-right: 2rem;
	margin-left: 2rem;
	text-align: center;
	max-width: calc(100% - 7.75rem);
}

@media (--mq-from-large) {
	.pagination__pages-container {
		max-width: calc(100% - 9rem);
	}
}

.pagination__pages {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}

.pagination__pages__item {
	margin: 0.5rem 0.5625rem;
}

@media (--mq-from-small) {
	.pagination__pages__item {
		margin-top: 0;
		margin-bottom: 0;
	}
}

@media (--mq-from-medium) {
	.pagination__pages__item {
		margin-right: 0.625rem;
		margin-left: 0.625rem;
	}
}

@media (--mq-from-wide) {
	.pagination__pages__item {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}
}

.pagination__pages__current-page-label,
.pagination__pages__item {
	display: block;
	font-size: 0.875rem;
	font-weight: var(--font-weight-medium);
}

@media (--mq-from-medium) {
	.pagination__pages__current-page-label,
	.pagination__pages__item {
		font-size: 1rem;
	}
}

@media (--mq-from-large) {
	.pagination__pages__current-page-label,
	.pagination__pages__item {
		font-size: 1.25rem;
	}
}

.pagination__pages__link {
	color: var(--color-black);
	text-decoration: none;
	transition: opacity 96ms linear;
}

.pagination__pages__link:focus,
.pagination__pages__link:hover {
	opacity: 0.35;
}

.pagination__pages__link:focus {
	outline: 0;
}

.pagination__pages__current-page-label {
	opacity: 0.35;
}

.pagination__pages__current-page-label:hover {
	cursor: default;
}
