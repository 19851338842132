.l-collection {
	position: relative;
	width: 100%;
}

.l-collection__header {
	position: sticky;
	top: var(--site-header-height-small);
	z-index: var(--z-index-collection);
}

@media (--mq-from-medium) {
	.l-collection__header {
		top: var(--site-header-height-medium);
	}
}

@media (--mq-from-large) {
	.l-collection__header {
		top: var(--site-header-height-large);
	}
}

.l-collection__gallery {
	margin-top: 2.875rem;
	margin-bottom: 2.875rem;
}

@media (--mq-from-medium) {
	.l-collection__gallery {
		margin-top: 3.25rem;
		margin-bottom: 3.25rem;
	}
}

@media (--mq-from-wide) {
	.l-collection__gallery {
		margin-bottom: 6.5rem;
	}
}

@media (--mq-from-large) {
	.l-collection__gallery {
		margin-bottom: 11.0625rem;
	}
}

.l-collection__description {
	margin-top: 2.875rem;
	margin-bottom: 2.875rem;
}

@media (--mq-from-medium) {
	.l-collection__description {
		margin-top: 3.25rem;
		margin-bottom: 3.25rem;
	}
}

.l-collection__variations {
	margin-top: 0.9375rem;
	margin-bottom: 0.9375rem;
}

@media (--mq-from-medium) {
	.l-collection__variations {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-large) {
	.l-collection__variations {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

.l-collection__specs {
	margin-top: 3.0625rem;
	margin-bottom: 3.75rem;
}

@media (--mq-from-medium) {
	.l-collection__specs {
		margin-top: 3.125rem;
		margin-bottom: 3.125rem;
	}
}

@media (--mq-from-wide) {
	.l-collection__specs {
		margin-top: 4.5em;
		margin-bottom: 4.5em;
	}
}

@media (--mq-from-large) {
	.l-collection__specs {
		margin-top: 6.4375rem;
		margin-bottom: 6.4375rem;
	}
}

.l-collection__related-collections {
	margin-top: 2.875rem;
	margin-bottom: 2.875rem;
}

@media (--mq-from-medium) {
	.l-collection__related-collections {
		margin-top: 3.25rem;
		margin-bottom: 3.25rem;
	}
}

@media (--mq-from-wide) {
	.l-collection__related-collections {
		margin-top: 6.5rem;
		margin-bottom: 6.5rem;
	}
}

@media (--mq-from-large) {
	.l-collection__related-collections {
		margin-top: 11.0625rem;
		margin-bottom: 11.0625rem;
	}
}
