.l-product-overview {
}

/* #region Header */
.l-product-overview__header {
	margin-bottom: 1.875rem;
}

@media (--mq-from-medium) {
	.l-product-overview__header {
		margin-bottom: 3.4375rem;
	}
}
/* #endregion Header */

/* #region Main */
@media (--mq-from-medium) {
	.l-product-overview__main {
		display: flex;
		justify-content: space-between;
	}
}
/* #endregion Main */

/* #region Filter */
@media (--mq-from-medium) {
	.l-product-overview__filter {
		flex-basis: calc(25% - 0.625rem);
		flex-grow: 0;
		flex-shrink: 0;
		position: sticky;
		top: 2rem;
	}
}

@media (--mq-from-wide) {
	.l-product-overview__filter {
		flex-basis: calc(20% - 1rem);
	}
}

@media (--mq-from-large) {
	.l-product-overview__filter {
		flex-basis: calc(20% - 1.875rem);
	}
}
/* #endregion Filter */

/* #region Grid */
@media (--mq-from-medium) {
	.l-product-overview__grid {
		flex-basis: calc(75% - 0.625rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-wide) {
	.l-product-overview__grid {
		flex-basis: calc(80% - 1rem);
	}
}

@media (--mq-from-large) {
	.l-product-overview__grid {
		flex-basis: calc(80% - 1.875rem);
	}
}
/* #endregion Grid */
