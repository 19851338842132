.popup {
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	opacity: 0;
	outline: none;
	overflow: hidden;
	overflow-y: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: var(--z-index-popup);
}

.popup:focus {
	outline: none;
}

/* #region States. */
.popup[data-state="opening"],
.popup[data-state="open"],
.popup[data-state="closing"] {
	content-visibility: visible;
	display: block;
	opacity: 1;
	pointer-events: auto;
}
/* #endregion States. */

/* #region Container. */
.popup__container {
	border-radius: var(--border-radius-large);
	display: block;
	overflow: hidden;
	padding-bottom: 2.5rem;
	position: fixed;
	top: 1.25rem;
	left: 50%;
	transform: translateX(-50%) translateZ(0);
	width: calc(480px + 2 * 2.5rem);
	max-width: calc(100% - 40px);
	height: auto;
	z-index: var(--z-index-popup);
}

@media (--mq-from-small) {
	.popup__container {
		border-radius: 0;
		box-sizing: border-box;
		padding-right: 2.5rem;
		padding-bottom: 0;
		padding-left: 2.5rem;
	}
}

@media (min-height: 520px) {
	.popup__container {
		top: 50%;
		transform: translateX(-50%) translateY(-50%) translateZ(0);
	}
}
/* #endregion Container. */

/* #region Background. */
.popup__background {
	background-color: #00000066;
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-popup-background);
}
/* #endregion Background. */

/* #region Content. */
.popup__content {
	background-color: var(--color-white);
	border-radius: var(--border-radius-large);
	box-sizing: border-box;
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 1.25rem;
	position: relative;
	width: 100%;
	height: auto;
	max-height: calc(100vh - 5rem);
}

@media (--mq-from-medium) and (max-height: 639px) {
	.popup__content {
		max-height: calc(100vh - 5rem);
	}
}

@media (--mq-from-large) and (max-height: 639px) {
	.popup__content {
		max-height: calc(100vh - 5rem);
	}
}

@media (min-height: 520px) {
	.popup__content {
		max-height: none;
	}
}

@media (--mq-from-wide) {
	.popup__content {
		padding: 2rem;
	}
}
/* #endregion Content. */

/* #region Content Inner. */
.popup__content__inner {
	position: relative;
	width: 100%;
	height: auto;
	min-height: 100%;
}
/* #endregion Content Inner. */

/* #region Title. */
.popup__title {
	display: inline-block;
	margin-bottom: 2.5rem;
}

@media (--mq-from-medium) and (--mq-up-to-wide) {
	.popup__title {
		width: 75%;
	}
}
/* #endregion Title. */

/* #region Close. */
.popup__close {
	position: absolute;
}

@media (--mq-up-to-small) {
	.popup__close {
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media (--mq-from-small) {
	.popup__close {
		top: 0;
		right: 0;
	}
}
/* #endregion Close. */

/* #region Close. */
.popup__close-button {
	appearance: none;
	background: none;
	background-color: var(--color-white);
	border: 0;
	border-radius: 50%;
	box-shadow: none;
	color: var(--color-black);
	cursor: pointer;
	display: inline-block;
	font-size: 1.75rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.75rem;
	overflow: hidden;
	padding: 0;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	width: 1.75rem;
	height: 1.75rem;
}

.popup__close-button:hover {
	background-color: var(--color-black);
	color: var(--color-white);
}
/* #endregion Close. */

/* #region Form Confirmation */
.popup__form-confirmation {
	height: 355px !important;
}
/* #endregion Form Confirmation */
