.icon-banner {
	color: var(--color-black);
	padding: 1.875rem 0;
	position: relative;
}

@media (--mq-from-small) {
	.icon-banner {
		padding: 2.25rem 0;
	}
}

@media (--mq-from-wide) {
	.icon-banner {
		padding: 6.625rem 0;
	}
}

.icon-banner__wrapper {
	box-sizing: border-box;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	width: 100%;
}

@media (--mq-from-medium) {
	.icon-banner__wrapper {
		margin-right: auto;
		margin-left: auto;
		max-width: 1170px;
	}
}

@media (--mq-from-large) {
	.icon-banner__wrapper {
		padding-right: 0;
		padding-left: 0;
		width: 60%;
	}
}

.icon-banner__content {
	display: flex;
	flex-direction: column;
}

@media (--mq-from-small) {
	.icon-banner__content {
		flex-direction: row;
		margin-right: -0.625rem;
		margin-left: -0.625rem;
		justify-content: center;
	}
}

.icon-banner__item {
	display: flex;
	margin: 1.125rem 0;
	align-items: center;
}

@media (--mq-from-small) {
	.icon-banner__item {
		flex-direction: column;
		padding-right: 0.625rem;
		padding-left: 0.625rem;
		width: 20%;
	}
}

.icon-banner__item__image {
}

.icon-banner__item__text {
	font-size: 0.75rem;
	padding-left: 1.25rem;
}

@media (--mq-from-small) {
	.icon-banner__item__text {
		font-size: 0.875rem;
		padding-top: 2.5rem;
		padding-left: 0;
		text-align: center;
	}
}
