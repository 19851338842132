.video-embed-player {
	background-color: var(--color-grey-light);
	border-radius: var(--border-radius-large);
	display: block;
	overflow: hidden;
	position: relative;
}

.video-embed-player[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.video-embed-player[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.video-embed-player[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

.video-embed-player[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

.video-embed-player[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

.video-embed-player[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

.video-embed-player[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

.video-embed-player[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

.video-embed-player[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

.video-embed-player__player-wrapper,
.video-embed-player__poster {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-embed-player__poster {
	cursor: pointer;
	transition: opacity 96ms 48ms linear;
	z-index: 2;
}

.video-embed-player.is-playing .video-embed-player__poster {
	opacity: 0;
	pointer-events: none;
}

.video-embed-player__player-wrapper {
	z-index: 1;
}

.video-embed-player__player-wrapper iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.video-embed-player__play {
	display: block;
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video-embed-player__play .arrow-icon {
	transform: translateZ(0);
	transition: transform 256ms ease-in-out;
}

.video-embed-player__poster:focus .video-embed-player__play .arrow-icon,
.video-embed-player__poster:hover .video-embed-player__play .arrow-icon {
	transform: scale(0.9) translateZ(0);
}

.video-embed-player__play__text {
	color: var(--color-white);
	display: block;
	font-weight: 500;
	margin-top: 0.625rem;
	text-decoration-color: rgb(255 255 255 / 1);
	text-decoration-line: underline;
	text-decoration-style: solid;
	transition: text-decoration-color 96ms linear;
}

@media (--mq-from-medium) {
	.video-embed-player__play__text {
		margin-top: 1.5rem;
	}
}

@media (--mq-from-large) {
	.video-embed-player__play__text {
		margin-top: 1.875rem;
	}
}

.video-embed-player__poster:focus .video-embed-player__play__text,
.video-embed-player__poster:hover .video-embed-player__play__text {
	text-decoration-color: rgb(255 255 255 / 0);
}

.video-embed-player--small-ui .video-embed-player__play__text {
	margin-top: 0;
}

@media (--mq-from-small) {
	.video-embed-player--small-ui .video-embed-player__play__text {
		margin-top: 0.5rem;
	}
}

@media (--mq-from-large) {
	.video-embed-player--small-ui .video-embed-player__play__text {
		margin-top: 0.75rem;
	}
}
