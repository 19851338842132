.product-variations {
	display: flex;
	align-items: flex-start;
}

.product-variations:not(:first-child) {
	margin-top: 1.25rem;
}

@media (--mq-up-to-medium) {
	.product-variations {
		-webkit-overflow-scrolling: touch;
		flex-wrap: nowrap;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
	}
}

@media (--mq-from-medium) {
	.product-variations {
		flex-wrap: wrap;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.product-variations:not(:first-child) {
		margin-top: 1.5rem;
	}
}

@media (--mq-from-large) {
	.product-variations {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

.product-variations__link {
	color: var(--color-black);
	display: block;
	text-decoration: none;
}

.product-variations__link:focus .product-variations__media,
.product-variations__link:hover .product-variations__media {
	transform: scale(1.1, 1.1);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}

@media (--mq-up-to-medium) {
	.product-variations__link {
		box-sizing: border-box;
		flex-shrink: 0;
		padding-left: 1.25rem;
		scroll-snap-align: start;
		width: 28vw;
	}

	.product-variations__link:last-child {
		padding-right: 1.25rem;
		width: calc(28vw + 1.25rem);
	}
}

@media (--mq-up-to-small) {
	.product-variations__link {
		width: 50vw;
	}

	.product-variations__link:last-child {
		width: calc(50vw + 1.25rem);
	}
}

@media (--mq-from-small) {
	.product-variations__link:first-child,
	.product-variations__link:nth-child(5n + 1) {
		margin-left: 0;
	}
}

@media (--mq-from-medium) {
	.product-variations__link {
		flex-basis: calc((100% - 4 * 1.25rem) / 5);
		margin-bottom: 1rem;
		margin-left: 1.25rem;
	}
}

@media (--mq-from-large) {
	.product-variations__link {
		flex-basis: calc((100% - 4 * 3.75rem) / 5);
		margin-bottom: 2rem;
		margin-left: 3.75rem;
	}
}

.product-variations__media {
	margin-bottom: 1rem;
	transition: transform 320ms ease-out;
	width: 100%;
	height: auto;
}

@media (--mq-from-large) {
	.product-variations__media {
		margin-bottom: 2rem;
	}
}

.product-variations__label {
	text-align: center;
}
