.gallery-b {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (max-width: 767px) {
	.gallery-b {
		box-sizing: border-box;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (min-width: 768px) {
	.gallery-b__track {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
	}
}

@media (min-width: 768px) {
	.gallery-b.has-rendered .gallery-b__track {
		transition: transform 768ms cubic-bezier(0.745, 0, 0.01, 1);
	}
}

@media (min-width: 768px) {
	.gallery-b:not(.has-rendered) .gallery-b__track {
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		overflow-x: scroll;
		/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
		overflow-y: none;
		scroll-snap-type: x mandatory;
		scrollbar-width: none;
	}
}

@media (min-width: 768px) {
	.gallery-b:not(.has-rendered) .gallery-b__track::-webkit-scrollbar {
		width: 0 !important;
	}
}

@media (min-width: 768px) {
	.gallery-b__item {
		flex-basis: 88%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 6%;
		margin-left: 6%;
		scroll-snap-align: center;
		text-align: center;
	}
}

@media (min-width: 1280px) {
	.gallery-b__item {
		flex-basis: 80%;
		margin-right: 10%;
		margin-left: 10%;
	}
}

@media (min-width: 1440px) {
	.gallery-b__item {
		flex-basis: 73%;
		margin-right: 8.5%;
		margin-left: 8.5%;
	}
}

@media (min-width: 768px) {
	.gallery-b:not(.has-rendered) .gallery-b__item {
		scroll-snap-align: center;
	}
}

@media (min-width: 768px) {
	.gallery-b__item:first-child:not(:only-child) {
		padding-left: 6%;
	}
}

@media (min-width: 1280px) {
	.gallery-b__item:first-child:not(:only-child) {
		padding-left: 10%;
	}
}

@media (min-width: 1440px) {
	.gallery-b__item:first-child:not(:only-child) {
		padding-left: 13.5%;
	}
}

@media (min-width: 768px) {
	.gallery-b__item:last-child:not(:only-child) {
		padding-right: 6%;
	}
}

@media (min-width: 1280px) {
	.gallery-b__item:last-child:not(:only-child) {
		padding-right: 10%;
	}
}

@media (min-width: 1440px) {
	.gallery-b__item:last-child:not(:only-child) {
		padding-right: 13.5%;
	}
}

@media (max-width: 767px) {
	.gallery-b__item:not(:last-child) {
		margin-bottom: 0.8125rem;
	}
}

.gallery-b__item:only-child {
	margin-right: auto;
	margin-left: auto;
}

.gallery-b__item__media {
	width: 100%;
}

.gallery-b__item__media:not(:last-child) {
	margin-bottom: 0.8125rem;
}

@media (min-width: 768px) {
	.gallery-b__item__media:not(:last-child) {
		margin-bottom: 1.6875rem;
	}
}

@media (min-width: 1680px) {
	.gallery-b__item__media:not(:last-child) {
		margin-bottom: 2.6875rem;
	}
}

.gallery-b__item__caption {
	font-size: 0.75rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.25;
}

@media (min-width: 768px) {
	.gallery-b__item__caption {
		margin-right: auto;
		margin-left: auto;
		max-width: 400px;
	}
}

@media (min-width: 1024px) {
	.gallery-b__item__caption {
		font-size: 0.875rem;
		line-height: 1.2857;
		max-width: 480px;
	}
}

@media (min-width: 1380px) {
	.gallery-b__item__caption {
		font-size: 1.125rem;
		line-height: 1.2222;
		/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
		max-width: 64 0;
	}
}

.gallery-b__item__caption > p {
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

.gallery-b__arrow {
	display: none;
}

@media (min-width: 768px) {
	.gallery-b__arrow {
		background-color: var(--color-white);
		border-radius: 50%;
		color: var(--color-grey-light);
		display: block;
		position: absolute;
		top: calc((88vw) / 16 * 9 / 2); /* Half height of 16:9. */
		width: 45px;
		height: 45px;
	}
}

@media (min-width: 1024px) {
	.gallery-b__arrow {
		width: 55px;
		height: 55px;
	}
}

@media (min-width: 1280px) {
	.gallery-b__arrow {
		top: calc((80vw) / 16 * 9 / 2); /* Half height of 16:9. */
	}
}

@media (min-width: 1440px) {
	.gallery-b__arrow {
		top: calc((73vw) / 16 * 9 / 2); /* Half height of 16:9. */
	}
}

@media (min-width: 1680px) {
	.gallery-b__arrow {
		width: 64px;
		height: 64px;
	}
}

@media (min-width: 768px) {
	.gallery-b__arrow.gallery-b__arrow--previous {
		left: 6%;
		transform: translate(-50%, -50%) translateX(-0.625rem);
	}
}

@media (min-width: 1280px) {
	.gallery-b__arrow.gallery-b__arrow--previous {
		left: 10%;
		transform: translate(-50%, -50%) translateX(-1.375rem);
	}
}

@media (min-width: 1440px) {
	.gallery-b__arrow.gallery-b__arrow--previous {
		left: 13.5%;
		transform: translate(-50%, -50%) translateX(-1.375rem);
	}
}

@media (min-width: 768px) {
	.gallery-b__arrow.gallery-b__arrow--next {
		right: 6%;
		transform: translate(50%, -50%) translateX(0.625rem);
	}
}

@media (min-width: 1280px) {
	.gallery-b__arrow.gallery-b__arrow--next {
		right: 10%;
		transform: translate(50%, -50%) translateX(1.375rem);
	}
}

@media (min-width: 1440px) {
	.gallery-b__arrow.gallery-b__arrow--next {
		right: 13.5%;
		transform: translate(50%, -50%) translateX(1.375rem);
	}
}

.gallery-b__arrow:not(.gallery-b__arrow--active) {
	pointer-events: none;
}

.gallery-b__arrow:not(.gallery-b__arrow--active):hover {
	cursor: default;
}

.gallery-b__arrow.gallery-b__arrow--active {
	box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 0.25);
	color: var(--color-black);
	opacity: 1;
}

.gallery-b__arrow.gallery-b__arrow--active:hover::after {
	opacity: 0;
}

.gallery-b__arrow.gallery-b__arrow--active:hover .gallery-b__arrow__hoverstate {
	opacity: 1;
}

.gallery-b__arrow .gallery-b__arrow__hoverstate,
.gallery-b__arrow::after {
	border-color: transparent;
	border-style: solid;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: opacity 96ms linear;
	width: 0;
	height: 0;
}

.gallery-b__arrow::after {
	content: "";
}

.gallery-b__arrow .gallery-b__arrow__hoverstate {
	opacity: 0;
}

.gallery-b__arrow.gallery-b__arrow--previous .gallery-b__arrow__hoverstate,
.gallery-b__arrow.gallery-b__arrow--previous::after {
	border-top: 8px solid transparent;
	border-right: 16px solid currentColor;
	border-bottom: 8px solid transparent;
	transform: translate(-50%, -50%) translateX(-4px);
}

@media (min-width: 1680px) {
	.gallery-b__arrow.gallery-b__arrow--previous .gallery-b__arrow__hoverstate,
	.gallery-b__arrow.gallery-b__arrow--previous::after {
		border-top: 12px solid transparent;
		border-right: 24px solid currentColor;
		border-bottom: 12px solid transparent;
		transform: translate(-50%, -50%) translateX(-6px);
	}
}

.gallery-b__arrow.gallery-b__arrow--next .gallery-b__arrow__hoverstate,
.gallery-b__arrow.gallery-b__arrow--next::after {
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-left: 16px solid currentColor;
	transform: translate(-50%, -50%) translateX(4px);
}

@media (min-width: 1680px) {
	.gallery-b__arrow.gallery-b__arrow--next .gallery-b__arrow__hoverstate,
	.gallery-b__arrow.gallery-b__arrow--next::after {
		border-top: 12px solid transparent;
		border-bottom: 12px solid transparent;
		border-left: 24px solid currentColor;
		transform: translate(-50%, -50%) translateX(6px);
	}
}

.gallery-b:not(.has-rendered) .gallery-b__arrow {
	display: none;
}

.gallery-b__arrow__label {
	visibility: hidden;
}
