.locations-actions {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	.locations-actions {
		margin-top: 2.625rem;
		margin-bottom: 2.625rem;
	}
}

@media (--mq-from-large) {
	.locations-actions {
		margin-top: 3.75rem;
		margin-bottom: 3.75rem;
	}
}
