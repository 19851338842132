/* Assumes to live inside a .section or other relatively positioned element. */
.anchor {
	border: none;
	clip: rect(0 0 0 0);
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	top: calc(var(--site-header-height-small) * -1);
	left: 0;
	white-space: nowrap;
	width: 1px;
	height: 1px;
}

@media (--mq-from-medium) {
	.anchor {
		top: calc(var(--site-header-height-medium) * -1);
	}
}

@media (--mq-from-large) {
	.anchor {
		top: calc(var(--site-header-height-large) * -1);
	}
}
