.location {
	display: flex;
	flex-wrap: wrap;
	padding-top: 2rem;
	padding-bottom: 2rem;
	justify-content: space-between;
}

@media (--mq-from-small) {
	.location {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

@media (--mq-from-large) {
	.location {
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
	}
}

.location + .location {
	border-top: 1px solid var(--color-black);
}

.location__header,
.location__column {
	flex-basis: 100%;
}

@media (--mq-from-small) {
	.location__header,
	.location__column {
		display: inline-block;
		flex-basis: calc((100% - 4rem) / 3);
		vertical-align: top;
	}
}

@media (--mq-from-wide) {
	.location__header,
	.location__column {
		font-size: 1rem;
	}
}

@media (--mq-up-to-small) {
	.location__header {
		margin-bottom: 2rem;
	}
}

@media (--mq-up-to-small) {
	.location__column {
		display: inline-block;
		flex-basis: calc(50% - 1rem);
		vertical-align: top;
	}
}
