.collection-header {
	color: var(--color-black);
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

@media (--mq-from-small) {
	.collection-header {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@media (--mq-from-large) {
	.collection-header {
		padding-top: 1.5625rem;
		padding-bottom: 1.5625rem;
	}
}

.collection-header__wrapper {
	position: relative;
	min-height: 20px;
}

@media (--mq-from-medium) {
	.collection-header__wrapper {
		min-height: 25px;
	}
}

@media (--mq-from-large) {
	.collection-header__wrapper {
		min-height: 30px;
	}
}

.collection-header__back {
	color: black;
	position: absolute;
	text-decoration: none;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
}

.collection-header__back__icon,
.collection-header__back__label {
	display: inline-block;
	vertical-align: middle;
}

.collection-header__back__icon {
	font-size: 0;
}

.collection-header__back__label {
	margin-left: 1rem;
}

@media (--mq-up-to-medium) {
	.collection-header__back__label {
		display: none;
	}
}

.collection-header__title {
	display: block;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	width: 100%;
	max-width: calc(100% - 80px);
}

@media (--mq-from-medium) {
	.collection-header__title {
		max-width: calc(100% - 400px);
	}
}
